import React, { PropsWithChildren } from 'react';
import { Document, Page } from '@react-pdf/renderer';
import { PDFProps } from '../PDFProps';
import { PDFTheme } from '../themes/types';
import { defaultTheme } from '../themes/presets/default';
import { createCommonStyles } from '../utils/styles';
import { getPageSize, PAGE_MARGINS, PDFPageSize } from '../utils/page';

export interface BaseTemplateProps
  extends Omit<PDFProps, 'resume' | 'resumeId'> {
  theme?: Partial<PDFTheme>;
  pageSize?: PDFPageSize;
  margins?: typeof PAGE_MARGINS.medium;
}

export const BaseTemplate: React.FC<PropsWithChildren<BaseTemplateProps>> = ({
  children,
  user,
  language,
  title,
  theme: themeOverrides,
  pageSize = 'A4',
  margins = PAGE_MARGINS.medium,
}) => {
  const theme: PDFTheme = {
    ...defaultTheme,
    ...themeOverrides,
    colors: {
      ...defaultTheme.colors,
      ...(themeOverrides?.colors || {}),
    },
    typography: {
      ...defaultTheme.typography,
      ...(themeOverrides?.typography || {}),
    },
    spacing: {
      ...defaultTheme.spacing,
      ...(themeOverrides?.spacing || {}),
    },
  };

  const styles = createCommonStyles(theme);

  return (
    <Document
      author={`${user.profile?.firstName || ''} ${user.profile?.lastName || ''}`}
      producer="ResuFit"
      creator="ResuFit"
      title={title}
      creationDate={new Date()}
      language={language}
    >
      <Page
        size={getPageSize(pageSize)}
        style={{
          ...styles.page,
          paddingTop: margins.top,
          paddingRight: margins.right,
          paddingBottom: margins.bottom,
          paddingLeft: margins.left,
        }}
      >
        {children}
      </Page>
    </Document>
  );
};
