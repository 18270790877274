import { Document, Page, PageProps, Styles } from '@react-pdf/renderer';
import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { PDFProps } from '../PDFProps';
import { createStyles } from './CreateResumePDFStyles';

export const DEFAULT_PRIMARY_COLOR = '#2F7D7A';
export const DEFAULT_HEADER_FONT = 'Nunito';
export const DEFAULT_TEXT_FONT = 'Roboto';
export const DEFAULT_PAGE_SIZE = 'A4';

export interface ResumePDFProps extends Omit<PDFProps, 'resume' | 'resumeId'> {
  children: ReactNode;
  customStyles?: Styles;
}

export const ResumePDF: React.FC<ResumePDFProps> = ({
  children,
  user,
  language,
  title,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
  customStyles,
}) => {
  const base = useMemo(
    () => createStyles(primaryColor, headerFont, textFont),
    [primaryColor, headerFont, textFont],
  );
  const styles = customStyles ? customStyles : base;

  return (
    <Document
      author={`${user.profile?.firstName || ''} ${user.profile?.lastName || ''}`}
      producer="ResuFit"
      creator="ResuFit"
      title={title}
      creationDate={new Date()}
      language={language}
    >
      <Page
        size={pageSize as Readonly<PropsWithChildren<PageProps>>['size']}
        style={styles.page}
      >
        {children}
      </Page>
    </Document>
  );
};
