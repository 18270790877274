import metadata from './ibm_plex_sans.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import ibm_plex_sansUrlitalic100 from './ttf/ibm_plex_sans/ibm_plex_sans-italic-100.ttf?url';
import ibm_plex_sansUrlitalic200 from './ttf/ibm_plex_sans/ibm_plex_sans-italic-200.ttf?url';
import ibm_plex_sansUrlitalic300 from './ttf/ibm_plex_sans/ibm_plex_sans-italic-300.ttf?url';
import ibm_plex_sansUrlitalic400 from './ttf/ibm_plex_sans/ibm_plex_sans-italic-400.ttf?url';
import ibm_plex_sansUrlitalic500 from './ttf/ibm_plex_sans/ibm_plex_sans-italic-500.ttf?url';
import ibm_plex_sansUrlitalic600 from './ttf/ibm_plex_sans/ibm_plex_sans-italic-600.ttf?url';
import ibm_plex_sansUrlitalic700 from './ttf/ibm_plex_sans/ibm_plex_sans-italic-700.ttf?url';
import ibm_plex_sansUrlnormal100 from './ttf/ibm_plex_sans/ibm_plex_sans-normal-100.ttf?url';
import ibm_plex_sansUrlnormal200 from './ttf/ibm_plex_sans/ibm_plex_sans-normal-200.ttf?url';
import ibm_plex_sansUrlnormal300 from './ttf/ibm_plex_sans/ibm_plex_sans-normal-300.ttf?url';
import ibm_plex_sansUrlnormal400 from './ttf/ibm_plex_sans/ibm_plex_sans-normal-400.ttf?url';
import ibm_plex_sansUrlnormal500 from './ttf/ibm_plex_sans/ibm_plex_sans-normal-500.ttf?url';
import ibm_plex_sansUrlnormal600 from './ttf/ibm_plex_sans/ibm_plex_sans-normal-600.ttf?url';
import ibm_plex_sansUrlnormal700 from './ttf/ibm_plex_sans/ibm_plex_sans-normal-700.ttf?url';

const fontConfig: FontConfig = {
  family: 'IBM Plex Sans',
  fonts: [
    {
      src: ibm_plex_sansUrlitalic100,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: ibm_plex_sansUrlitalic200,
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: ibm_plex_sansUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: ibm_plex_sansUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: ibm_plex_sansUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: ibm_plex_sansUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: ibm_plex_sansUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: ibm_plex_sansUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: ibm_plex_sansUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: ibm_plex_sansUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: ibm_plex_sansUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: ibm_plex_sansUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: ibm_plex_sansUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: ibm_plex_sansUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
