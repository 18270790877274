export type PDFPageSize = 'A4' | 'Letter' | 'Legal';
export type PDFOrientation = 'portrait' | 'landscape';

export interface PDFPageConfig {
  size: PDFPageSize;
  orientation?: PDFOrientation;
}

// Standard page sizes in points (72 points = 1 inch)
export const PAGE_SIZES: Record<PDFPageSize, [number, number]> = {
  A4: [595.28, 841.89], // 210mm × 297mm
  Letter: [612, 792], // 8.5" × 11"
  Legal: [612, 1008], // 8.5" × 14"
};

export const getPageSize = (
  size: PDFPageSize,
  orientation: PDFOrientation = 'portrait',
): [number, number] => {
  const [width, height] = PAGE_SIZES[size];
  return orientation === 'portrait' ? [width, height] : [height, width];
};

// Common page margins in points
export const PAGE_MARGINS = {
  small: {
    top: 36, // 0.5"
    right: 36,
    bottom: 36,
    left: 36,
  },
  medium: {
    top: 54, // 0.75"
    right: 54,
    bottom: 54,
    left: 54,
  },
  large: {
    top: 72, // 1"
    right: 72,
    bottom: 72,
    left: 72,
  },
};

// Calculate available content area
export const getContentArea = (
  size: PDFPageSize,
  orientation: PDFOrientation = 'portrait',
  margins = PAGE_MARGINS.medium,
) => {
  const [width, height] = getPageSize(size, orientation);
  return {
    width: width - margins.left - margins.right,
    height: height - margins.top - margins.bottom,
  };
};
