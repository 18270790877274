import { List, Paper, Stack, ThemeIcon, Title } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubscribeCTAOverlay } from '../SubscribeCTAOverlay/SubscribeCTAOverlay';
import styles from './TipsSection.module.css';

const TipsList: React.FC<{ tips: string[] }> = ({ tips }) => (
  <List
    mt="md"
    spacing="md"
    size="md"
    withPadding
    icon={
      <ThemeIcon variant="subtle" size="xl" radius="xl" c="blue.3">
        <IconCircleCheck size={32} />
      </ThemeIcon>
    }
  >
    {tips.map((tip, idx) => (
      <List.Item key={idx} className={styles.listItem}>
        {tip}
      </List.Item>
    ))}
  </List>
);

export const TipsSection: React.FC<{ tips: string[]; hidden: boolean }> = ({
  tips,
  hidden,
}) => {
  const { t } = useTranslation();

  if (!Array.isArray(tips)) {
    return null;
  }

  const shownTips = hidden ? tips.slice(0, 1) : tips;
  const hiddenTips = hidden ? tips.slice(1) : [];

  return (
    <Stack>
      <Title order={3}>{t('view.tips')}</Title>

      <Paper p="lg" withBorder shadow="md">
        <div className={styles.container}>
          <TipsList tips={shownTips} />

          {hidden && (
            <div className={styles.overlayContainer}>
              <TipsList tips={hiddenTips} />
              <SubscribeCTAOverlay enabled={hidden} />
            </div>
          )}
        </div>
      </Paper>
    </Stack>
  );
};
