import type { FontCombination } from './font-types';
import { getFontNames } from './font-types';
import { fontMetadata } from './font-metadata';

const getFontPair = (headerFont: string, textFont: string) => {
  const headerNames = getFontNames(headerFont, fontMetadata);
  const textNames = getFontNames(textFont, fontMetadata);
  return {
    headerFont: headerNames.pdfName,
    headerFontUI: headerNames.uiName,
    textFont: textNames.pdfName,
    textFontUI: textNames.uiName,
  };
};

export const popularFontCombinations: FontCombination[] = [
  {
    id: 'modern-professional',
    name: 'Modern Professional',
    ...getFontPair('Montserrat', 'Open Sans'),
    category: 'modern',
    description: {
      en: 'A contemporary combination perfect for professional resumes',
      de: 'Eine zeitgemäße Kombination, perfekt für professionelle Lebensläufe',
      fr: 'Une combinaison contemporaine parfaite pour les CV professionnels',
      es: 'Una combinación contemporánea perfecta para currículums profesionales',
      it: 'Una combinazione contemporanea perfetta per curriculum professionali',
      pt: 'Uma combinação contemporânea perfeita para currículos profissionais',
    },
    sampleText: {
      heading: 'Professional Experience',
      body: 'Led cross-functional teams in developing innovative solutions',
    },
  },
  {
    id: 'classic-elegant',
    name: 'Classic Elegant',
    ...getFontPair('Playfair Display', 'Lato'),
    category: 'classic',
    description: {
      en: 'An elegant pairing that combines tradition with readability',
      de: 'Eine elegante Kombination, die Tradition mit Lesbarkeit verbindet',
      fr: 'Un mariage élégant qui allie tradition et lisibilité',
      es: 'Una combinación elegante que une tradición y legibilidad',
      it: 'Un abbinamento elegante che unisce tradizione e leggibilità',
      pt: 'Uma combinação elegante que une tradição e legibilidade',
    },
    sampleText: {
      heading: 'Education & Achievements',
      body: 'Bachelor of Science in Computer Science, Magna Cum Laude',
    },
  },
  {
    id: 'modern-minimal',
    name: 'Modern Minimal',
    ...getFontPair('Nunito', 'Source Sans 3'),
    category: 'modern',
    description: {
      en: 'A clean and minimal combination for a contemporary look',
      de: 'Eine klare und minimalistische Kombination für einen zeitgemäßen Look',
      fr: 'Une combinaison épurée et minimaliste pour un look contemporain',
      es: 'Una combinación limpia y minimalista para un aspecto contemporáneo',
      it: 'Una combinazione pulita e minimalista per un look contemporaneo',
      pt: 'Uma combinação limpa e minimalista para um visual contemporâneo',
    },
    sampleText: {
      heading: 'Technical Skills',
      body: 'Proficient in React, TypeScript, and Node.js',
    },
  },
  {
    id: 'bold-impact',
    name: 'Bold Impact',
    ...getFontPair('Oswald', 'Open Sans'),
    category: 'modern',
    description: {
      en: 'A bold and impactful combination that stands out',
      de: 'Eine kühne und eindrucksvolle Kombination, die sich abhebt',
      fr: 'Une combinaison audacieuse et percutante qui se démarque',
      es: 'Una combinación audaz e impactante que destaca',
      it: "Una combinazione audace e d'impatto che si distingue",
      pt: 'Uma combinação ousada e impactante que se destaca',
    },
    sampleText: {
      heading: 'Leadership Experience',
      body: 'Successfully managed and delivered multiple high-impact projects',
    },
  },
  {
    id: 'creative-modern',
    name: 'Creative Modern',
    ...getFontPair('Work Sans', 'DM Sans'),
    category: 'creative',
    description: {
      en: 'A modern and creative pairing for design-focused resumes',
      de: 'Eine moderne und kreative Kombination für designorientierte Lebensläufe',
      fr: 'Un duo moderne et créatif pour les CV axés sur le design',
      es: 'Una combinación moderna y creativa para currículums centrados en el diseño',
      it: 'Un abbinamento moderno e creativo per curriculum incentrati sul design',
      pt: 'Uma combinação moderna e criativa para currículos focados em design',
    },
    sampleText: {
      heading: 'Portfolio Highlights',
      body: 'Created innovative design solutions for diverse client projects',
    },
  },
  {
    id: 'professional-clean',
    name: 'Professional Clean',
    ...getFontPair('Inter', 'Source Sans 3'),
    category: 'modern',
    description: {
      en: 'A clean and professional combination with excellent readability',
      de: 'Eine klare und professionelle Kombination mit ausgezeichneter Lesbarkeit',
      fr: 'Une combinaison nette et professionnelle avec une excellente lisibilité',
      es: 'Una combinación limpia y profesional con excelente legibilidad',
      it: 'Una combinazione pulita e professionale con eccellente leggibilità',
      pt: 'Uma combinação limpa e profissional com excelente legibilidade',
    },
    sampleText: {
      heading: 'Core Competencies',
      body: 'Expert in strategic planning and team leadership',
    },
  },
];

export default popularFontCombinations;
