import React from 'react';
import { PDFTheme } from '../../themes/types';
import { Section } from '../layout/Section';
import { Heading } from '../typography/Heading';
import { Style } from '@react-pdf/types';
import { BulletPoints } from '../typography/BulletPoints';

export interface SkillsSectionProps {
  theme: PDFTheme;
  title?: string;
  skills: string[];
  style?: Style;
}

export const SkillsSection: React.FC<SkillsSectionProps> = ({
  theme,
  title = 'Skills',
  skills,
  style = {},
}) => {
  return (
    <Section theme={theme} style={style} wrap={false}>
      <Heading theme={theme} level={2}>
        {title}
      </Heading>
      <BulletPoints theme={theme} points={skills} />
    </Section>
  );
};
