import metadata from './inter_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import inter_variableUrlitalic100 from './ttf/inter_variable/inter_variable-italic-100.ttf?url';
import inter_variableUrlitalic200 from './ttf/inter_variable/inter_variable-italic-200.ttf?url';
import inter_variableUrlitalic300 from './ttf/inter_variable/inter_variable-italic-300.ttf?url';
import inter_variableUrlitalic400 from './ttf/inter_variable/inter_variable-italic-400.ttf?url';
import inter_variableUrlitalic500 from './ttf/inter_variable/inter_variable-italic-500.ttf?url';
import inter_variableUrlitalic600 from './ttf/inter_variable/inter_variable-italic-600.ttf?url';
import inter_variableUrlitalic700 from './ttf/inter_variable/inter_variable-italic-700.ttf?url';
import inter_variableUrlitalic800 from './ttf/inter_variable/inter_variable-italic-800.ttf?url';
import inter_variableUrlitalic900 from './ttf/inter_variable/inter_variable-italic-900.ttf?url';
import inter_variableUrlnormal100 from './ttf/inter_variable/inter_variable-normal-100.ttf?url';
import inter_variableUrlnormal200 from './ttf/inter_variable/inter_variable-normal-200.ttf?url';
import inter_variableUrlnormal300 from './ttf/inter_variable/inter_variable-normal-300.ttf?url';
import inter_variableUrlnormal400 from './ttf/inter_variable/inter_variable-normal-400.ttf?url';
import inter_variableUrlnormal500 from './ttf/inter_variable/inter_variable-normal-500.ttf?url';
import inter_variableUrlnormal600 from './ttf/inter_variable/inter_variable-normal-600.ttf?url';
import inter_variableUrlnormal700 from './ttf/inter_variable/inter_variable-normal-700.ttf?url';
import inter_variableUrlnormal800 from './ttf/inter_variable/inter_variable-normal-800.ttf?url';
import inter_variableUrlnormal900 from './ttf/inter_variable/inter_variable-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Inter',
  fonts: [
    {
      src: inter_variableUrlitalic100,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: inter_variableUrlitalic200,
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: inter_variableUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: inter_variableUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: inter_variableUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: inter_variableUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: inter_variableUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: inter_variableUrlitalic800,
      fontStyle: 'italic',
      fontWeight: 800,
    },
    {
      src: inter_variableUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: inter_variableUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: inter_variableUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: inter_variableUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: inter_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: inter_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: inter_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: inter_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: inter_variableUrlnormal800,
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: inter_variableUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
