import { useMutation, useQuery } from '@tanstack/react-query';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';

export interface DocumentStyle {
  templateName: string;
  coverLetterTemplateName: string;
  primaryColor: string;
  pageSize: string;
  headerFont: string;
  textFont: string;
  fontMode: 'custom' | 'popular';
}

const getDocumentStylesQueryKey = (resumeId: string) => [
  'documentStyles',
  resumeId,
];

export const useDocumentStyles = (resumeId: string) => {
  return useQuery({
    queryKey: getDocumentStylesQueryKey(resumeId),
    queryFn: async () => {
      const url = ApiRoutes.DocumentStyles.replace(':id', resumeId);
      return Api.ky.get(url).json<DocumentStyle>();
    },
  });
};

export const useUpdateDocumentStyles = (resumeId: string) => {
  return useMutation({
    mutationFn: async (styles: DocumentStyle) => {
      const url = ApiRoutes.DocumentStyles.replace(':id', resumeId);
      return Api.ky.put(url, { json: { styles } }).json<DocumentStyle>();
    },
  });
};
