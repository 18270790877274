import { Style } from '@react-pdf/types';
import { PDFTheme } from '../themes/types';

export const createCommonStyles = (theme: PDFTheme) => ({
  page: {
    padding: theme.spacing.lg,
    fontFamily: theme.typography.textFont.pdfName,
    fontSize: theme.typography.sizes.body,
    color: theme.colors.text,
    backgroundColor: theme.colors.background,
  } as Style,
  section: {
    marginBottom: theme.spacing.lg,
    padding: theme.spacing.md,
    backgroundColor: theme.colors.surface,
  } as Style,
  header: {
    marginBottom: theme.spacing.lg,
    textAlign: 'center',
  } as Style,
  title: {
    fontSize: theme.typography.sizes.h1,
    fontFamily: theme.typography.headerFont.pdfName,
    color: theme.colors.primary,
    marginBottom: theme.spacing.md,
  } as Style,
  subtitle: {
    fontSize: theme.typography.sizes.h2,
    fontFamily: theme.typography.headerFont.pdfName,
    color: theme.colors.secondary,
    marginBottom: theme.spacing.sm,
  } as Style,
  text: {
    fontSize: theme.typography.sizes.body,
    marginBottom: theme.spacing.xs,
  } as Style,
  small: {
    fontSize: theme.typography.sizes.small,
    color: theme.colors.muted,
  } as Style,
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -theme.spacing.sm,
  } as Style,
  gridItem: (columns: number): Style => ({
    width: `${100 / columns}%`,
    paddingHorizontal: theme.spacing.sm,
  }),
  bulletPoint: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: theme.spacing.xs,
  } as Style,
  bulletPointSymbol: {
    width: 10,
    marginRight: theme.spacing.xs,
    color: theme.colors.primary,
  } as Style,
  bulletPointText: {
    flex: 1,
  } as Style,
});
