import metadata from './quicksand_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import quicksand_variableUrlnormal300 from './ttf/quicksand_variable/quicksand_variable-normal-300.ttf?url';
import quicksand_variableUrlnormal400 from './ttf/quicksand_variable/quicksand_variable-normal-400.ttf?url';
import quicksand_variableUrlnormal500 from './ttf/quicksand_variable/quicksand_variable-normal-500.ttf?url';
import quicksand_variableUrlnormal600 from './ttf/quicksand_variable/quicksand_variable-normal-600.ttf?url';
import quicksand_variableUrlnormal700 from './ttf/quicksand_variable/quicksand_variable-normal-700.ttf?url';

const fontConfig: FontConfig = {
  family: 'Quicksand',
  fonts: [
    {
      src: quicksand_variableUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: quicksand_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: quicksand_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: quicksand_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: quicksand_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
