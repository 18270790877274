import metadata from './outfit_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import outfit_variableUrlnormal100 from './ttf/outfit_variable/outfit_variable-normal-100.ttf?url';
import outfit_variableUrlnormal200 from './ttf/outfit_variable/outfit_variable-normal-200.ttf?url';
import outfit_variableUrlnormal300 from './ttf/outfit_variable/outfit_variable-normal-300.ttf?url';
import outfit_variableUrlnormal400 from './ttf/outfit_variable/outfit_variable-normal-400.ttf?url';
import outfit_variableUrlnormal500 from './ttf/outfit_variable/outfit_variable-normal-500.ttf?url';
import outfit_variableUrlnormal600 from './ttf/outfit_variable/outfit_variable-normal-600.ttf?url';
import outfit_variableUrlnormal700 from './ttf/outfit_variable/outfit_variable-normal-700.ttf?url';
import outfit_variableUrlnormal800 from './ttf/outfit_variable/outfit_variable-normal-800.ttf?url';
import outfit_variableUrlnormal900 from './ttf/outfit_variable/outfit_variable-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Outfit',
  fonts: [
    {
      src: outfit_variableUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: outfit_variableUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: outfit_variableUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: outfit_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: outfit_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: outfit_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: outfit_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: outfit_variableUrlnormal800,
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: outfit_variableUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
