import metadata from './ibm_plex_serif.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import ibm_plex_serifUrlitalic100 from './ttf/ibm_plex_serif/ibm_plex_serif-italic-100.ttf?url';
import ibm_plex_serifUrlitalic200 from './ttf/ibm_plex_serif/ibm_plex_serif-italic-200.ttf?url';
import ibm_plex_serifUrlitalic300 from './ttf/ibm_plex_serif/ibm_plex_serif-italic-300.ttf?url';
import ibm_plex_serifUrlitalic400 from './ttf/ibm_plex_serif/ibm_plex_serif-italic-400.ttf?url';
import ibm_plex_serifUrlitalic500 from './ttf/ibm_plex_serif/ibm_plex_serif-italic-500.ttf?url';
import ibm_plex_serifUrlitalic600 from './ttf/ibm_plex_serif/ibm_plex_serif-italic-600.ttf?url';
import ibm_plex_serifUrlitalic700 from './ttf/ibm_plex_serif/ibm_plex_serif-italic-700.ttf?url';
import ibm_plex_serifUrlnormal100 from './ttf/ibm_plex_serif/ibm_plex_serif-normal-100.ttf?url';
import ibm_plex_serifUrlnormal200 from './ttf/ibm_plex_serif/ibm_plex_serif-normal-200.ttf?url';
import ibm_plex_serifUrlnormal300 from './ttf/ibm_plex_serif/ibm_plex_serif-normal-300.ttf?url';
import ibm_plex_serifUrlnormal400 from './ttf/ibm_plex_serif/ibm_plex_serif-normal-400.ttf?url';
import ibm_plex_serifUrlnormal500 from './ttf/ibm_plex_serif/ibm_plex_serif-normal-500.ttf?url';
import ibm_plex_serifUrlnormal600 from './ttf/ibm_plex_serif/ibm_plex_serif-normal-600.ttf?url';
import ibm_plex_serifUrlnormal700 from './ttf/ibm_plex_serif/ibm_plex_serif-normal-700.ttf?url';

const fontConfig: FontConfig = {
  family: 'IBM Plex Serif',
  fonts: [
    {
      src: ibm_plex_serifUrlitalic100,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: ibm_plex_serifUrlitalic200,
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: ibm_plex_serifUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: ibm_plex_serifUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: ibm_plex_serifUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: ibm_plex_serifUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: ibm_plex_serifUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: ibm_plex_serifUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: ibm_plex_serifUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: ibm_plex_serifUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: ibm_plex_serifUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: ibm_plex_serifUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: ibm_plex_serifUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: ibm_plex_serifUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
