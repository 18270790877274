import metadata from './ubuntu.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import ubuntuUrlitalic300 from './ttf/ubuntu/ubuntu-italic-300.ttf?url';
import ubuntuUrlitalic400 from './ttf/ubuntu/ubuntu-italic-400.ttf?url';
import ubuntuUrlitalic500 from './ttf/ubuntu/ubuntu-italic-500.ttf?url';
import ubuntuUrlitalic700 from './ttf/ubuntu/ubuntu-italic-700.ttf?url';
import ubuntuUrlnormal300 from './ttf/ubuntu/ubuntu-normal-300.ttf?url';
import ubuntuUrlnormal400 from './ttf/ubuntu/ubuntu-normal-400.ttf?url';
import ubuntuUrlnormal500 from './ttf/ubuntu/ubuntu-normal-500.ttf?url';
import ubuntuUrlnormal700 from './ttf/ubuntu/ubuntu-normal-700.ttf?url';

const fontConfig: FontConfig = {
  family: 'Ubuntu',
  fonts: [
    {
      src: ubuntuUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: ubuntuUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: ubuntuUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: ubuntuUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: ubuntuUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: ubuntuUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: ubuntuUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: ubuntuUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
