import { Box, Group, Modal, Stack, Text, ThemeIcon, rem } from '@mantine/core';
import { IconTypography } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Font } from '../../pdf/utils/Font';

interface FontInfoDialogProps {
  opened: boolean;
  onClose: () => void;
  headerFont?: Font | null;
  textFont?: Font | null;
  headerPreviewText?: string;
  textPreviewText?: string;
}

export function FontInfoDialog({
  opened,
  onClose,
  headerFont,
  textFont,
  headerPreviewText = 'The quick brown fox jumps over the lazy dog',
  textPreviewText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
}: FontInfoDialogProps) {
  const { t } = useTranslation();

  const renderFontInfo = (
    font: Font | null | undefined,
    type: 'header' | 'text',
  ) => {
    if (!font) return null;

    return (
      <Stack gap="md">
        <Group gap="xs">
          <ThemeIcon variant="light" size="md">
            <IconTypography style={{ width: rem(16), height: rem(16) }} />
          </ThemeIcon>
          <Text fw={500} size="sm">
            {type === 'header'
              ? t('view.pdf.customFonts.headerFont')
              : t('view.pdf.customFonts.textFont')}
          </Text>
        </Group>

        <Box>
          <Text
            size={type === 'header' ? 'xl' : 'sm'}
            style={{
              fontFamily: font.uiName,
              marginBottom: rem(8),
            }}
          >
            {type === 'header' ? headerPreviewText : textPreviewText}
          </Text>
        </Box>

        <Stack gap="xs">
          <Text size="sm" fw={500}>
            {t('view.pdf.fontInfo.name')}
          </Text>
          <Text size="sm">{font.name}</Text>

          <Text size="sm" fw={500} mt="sm">
            {t('view.pdf.fontInfo.category')}
          </Text>
          <Text size="sm">{font.category}</Text>

          <Text size="sm" fw={500} mt="sm">
            {t('view.pdf.fontInfo.type')}
          </Text>
          <Text size="sm">
            {font.isVariable
              ? t('view.pdf.fontInfo.variable')
              : t('view.pdf.fontInfo.static')}
          </Text>

          <Text size="sm" fw={500} mt="sm">
            {t('view.pdf.fontInfo.weights')}
          </Text>
          <Text size="sm">{font.availableWeights.join(', ')}</Text>

          <Text size="sm" fw={500} mt="sm">
            {t('view.pdf.fontInfo.styles')}
          </Text>
          <Text size="sm">{font.availableStyles.join(', ')}</Text>
        </Stack>
      </Stack>
    );
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('view.pdf.fontInfo.title')}
      size="lg"
    >
      <Stack gap="xl">
        {renderFontInfo(headerFont, 'header')}
        {renderFontInfo(textFont, 'text')}
      </Stack>
    </Modal>
  );
}
