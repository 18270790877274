import {
  ActionIcon,
  Box,
  ColorInput,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useClickOutside, useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconPalette } from '@tabler/icons-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { MutableRefObject } from 'react';

const defaultColors = [
  '#1B4965', // Professional navy
  '#2F6690', // Business blue
  '#014F86', // Deep blue
  '#2D3748', // Slate gray
  '#374151', // Cool gray
  '#1A365D', // Dark blue
  '#065F46', // Forest green
  '#064E3B', // Dark green
  '#6B21A8', // Royal purple
  '#3730A3', // Indigo
  '#9D174D', // Burgundy
  '#7B1818', // Deep red
] as const;

interface ColorPickerProps {
  value?: string;
  onChange: (color: string) => void;
  label?: string;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  value,
  onChange,
  label,
}) => {
  const [opened, { toggle, close }] = useDisclosure(false);
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const ref = useClickOutside(() => {
    close();
  }) as MutableRefObject<HTMLDivElement>;

  return (
    <Stack gap="xs" ref={ref} align="stretch">
      <Text fz="sm" fw={500} c="dimmed">
        {label}
      </Text>
      <Box pos="relative">
        <ActionIcon
          variant="light"
          size="lg"
          onClick={toggle}
          style={{
            backgroundColor: value,
            border: `1px solid ${theme.colors.gray[3]}`,
          }}
        >
          <IconPalette
            style={{ color: value === '#FFFFFF' ? '#000000' : '#FFFFFF' }}
          />
        </ActionIcon>

        <AnimatePresence>
          {opened && (
            <motion.div
              style={{
                position: 'absolute',
                bottom: '100%',
                ...(isMobile
                  ? {
                      left: 0,
                      right: 0,
                    }
                  : {
                      right: 0,
                    }),
                marginBottom: theme.spacing.xs,
                zIndex: 1000,
                backgroundColor: theme.white,
                borderRadius: theme.radius.sm,
                boxShadow: theme.shadows.md,
                padding: theme.spacing.md,
                width: isMobile ? '100%' : 250,
              }}
              initial={{ opacity: 0, scale: 0.9, y: 20 }}
              animate={{
                opacity: 1,
                scale: 1,
                y: 0,
                transition: {
                  type: 'spring',
                  stiffness: 500,
                  damping: 25,
                  mass: 0.5,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.9,
                y: 20,
                transition: {
                  type: 'spring',
                  stiffness: 500,
                  damping: 25,
                  mass: 0.5,
                },
              }}
            >
              <Stack gap="md">
                <Group gap="xs" justify="flex-start" wrap="wrap">
                  {defaultColors.map((color) => (
                    <motion.div
                      key={color}
                      whileHover={{ scale: 1.2 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 17,
                      }}
                    >
                      <ActionIcon
                        size="lg"
                        variant="light"
                        onClick={() => {
                          onChange(color);
                          close();
                        }}
                        style={{
                          backgroundColor: color,
                          border: `2px solid ${
                            color === value
                              ? theme.colors.blue[5]
                              : theme.colors.gray[3]
                          }`,
                        }}
                      />
                    </motion.div>
                  ))}
                </Group>
                <ColorInput
                  label={t('view.pdf.customColorLabel')}
                  value={value}
                  onChange={onChange}
                  size="sm"
                />
              </Stack>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Stack>
  );
};
