import metadata from './montserrat_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import montserrat_variableUrlitalic100 from './ttf/montserrat_variable/montserrat_variable-italic-100.ttf?url';
import montserrat_variableUrlitalic200 from './ttf/montserrat_variable/montserrat_variable-italic-200.ttf?url';
import montserrat_variableUrlitalic300 from './ttf/montserrat_variable/montserrat_variable-italic-300.ttf?url';
import montserrat_variableUrlitalic400 from './ttf/montserrat_variable/montserrat_variable-italic-400.ttf?url';
import montserrat_variableUrlitalic500 from './ttf/montserrat_variable/montserrat_variable-italic-500.ttf?url';
import montserrat_variableUrlitalic600 from './ttf/montserrat_variable/montserrat_variable-italic-600.ttf?url';
import montserrat_variableUrlitalic700 from './ttf/montserrat_variable/montserrat_variable-italic-700.ttf?url';
import montserrat_variableUrlitalic800 from './ttf/montserrat_variable/montserrat_variable-italic-800.ttf?url';
import montserrat_variableUrlitalic900 from './ttf/montserrat_variable/montserrat_variable-italic-900.ttf?url';
import montserrat_variableUrlnormal100 from './ttf/montserrat_variable/montserrat_variable-normal-100.ttf?url';
import montserrat_variableUrlnormal200 from './ttf/montserrat_variable/montserrat_variable-normal-200.ttf?url';
import montserrat_variableUrlnormal300 from './ttf/montserrat_variable/montserrat_variable-normal-300.ttf?url';
import montserrat_variableUrlnormal400 from './ttf/montserrat_variable/montserrat_variable-normal-400.ttf?url';
import montserrat_variableUrlnormal500 from './ttf/montserrat_variable/montserrat_variable-normal-500.ttf?url';
import montserrat_variableUrlnormal600 from './ttf/montserrat_variable/montserrat_variable-normal-600.ttf?url';
import montserrat_variableUrlnormal700 from './ttf/montserrat_variable/montserrat_variable-normal-700.ttf?url';
import montserrat_variableUrlnormal800 from './ttf/montserrat_variable/montserrat_variable-normal-800.ttf?url';
import montserrat_variableUrlnormal900 from './ttf/montserrat_variable/montserrat_variable-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Montserrat',
  fonts: [
    {
      src: montserrat_variableUrlitalic100,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: montserrat_variableUrlitalic200,
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: montserrat_variableUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: montserrat_variableUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: montserrat_variableUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: montserrat_variableUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: montserrat_variableUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: montserrat_variableUrlitalic800,
      fontStyle: 'italic',
      fontWeight: 800,
    },
    {
      src: montserrat_variableUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: montserrat_variableUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: montserrat_variableUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: montserrat_variableUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: montserrat_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: montserrat_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: montserrat_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: montserrat_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: montserrat_variableUrlnormal800,
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: montserrat_variableUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
