import { useState } from 'react';
import {
  Modal,
  Stack,
  Text,
  Group,
  Button,
  Textarea,
  rem,
  Box,
  Progress,
  ThemeIcon,
  useMantineTheme,
  ScrollArea,
  Flex,
  Title,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useFinishTourMutation } from '../../queries/UserQueries';
import { IUser } from '../../models/User';
import { motion, AnimatePresence } from 'framer-motion';
import {
  IconMoodSad,
  IconMoodSmile,
  IconMoodHappy,
  IconSend,
  IconCheck,
} from '@tabler/icons-react';
import { useCreateNpsResponseMutation } from '../../queries/NpsQueries';

interface NPSSurveyProps {
  opened: boolean;
  onClose: () => void;
  user?: IUser;
}

const NPS_TOUR_KEY = 'nps-survey-done';

const MotionStack = motion(Stack);

const numberVariants = {
  hidden: {
    opacity: 0,
    x: 50,
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 20,
    },
  },
};

interface RadioButtonProps {
  value: number;
  checked: boolean;
  onChange: (value: string) => void;
  color: string;
}

const RadioButton = ({ value, checked, onChange, color }: RadioButtonProps) => (
  <Box
    component="button"
    type="button"
    onClick={() => {
      onChange(value.toString());
    }}
    style={{
      all: 'unset',
      cursor: 'pointer',
      display: 'inline-flex',
    }}
  >
    <ThemeIcon
      size={rem(48)}
      variant={checked ? 'filled' : 'light'}
      color={color}
      radius="md"
      style={{
        transition: 'all 0.2s ease',
      }}
    >
      {value}
    </ThemeIcon>
  </Box>
);

const MobileScoreButton = ({
  value,
  checked,
  onChange,
  color,
}: RadioButtonProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <Box
      component={motion.button}
      type="button"
      onClick={() => {
        onChange(value.toString());
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: value * 0.03 }}
      style={{
        all: 'unset',
        cursor: 'pointer',
        width: '100%',
      }}
    >
      <Flex
        gap="md"
        align="center"
        p="md"
        style={{
          border: `2px solid ${
            checked
              ? `var(--mantine-color-${color}-6)`
              : 'var(--mantine-color-gray-3)'
          }`,
          borderRadius: theme.radius.md,
          backgroundColor: checked
            ? `var(--mantine-color-${color}-0)`
            : undefined,
          position: 'relative',
        }}
      >
        <ThemeIcon
          size={rem(42)}
          variant={checked ? 'filled' : 'light'}
          color={color}
          radius="xl"
        >
          {value}
        </ThemeIcon>
        <Stack gap={2}>
          <Text
            fw={500}
            c={checked ? `var(--mantine-color-${color}-8)` : undefined}
          >
            {t(`nps.scoreLabels.${value}`)}
          </Text>
          <Text size="sm" opacity={0.75}>
            {t('nps.scoreHint', { score: value })}
          </Text>
        </Stack>
        {checked && (
          <Box
            component={motion.div}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            style={{ position: 'absolute', right: '1rem' }}
          >
            <IconCheck
              size={24}
              style={{ color: `var(--mantine-color-${color}-6)` }}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export const NPSSurvey: React.FC<NPSSurveyProps> = ({ opened, onClose }) => {
  const { t } = useTranslation();
  const [score, setScore] = useState<string>('');
  const [feedback, setFeedback] = useState('');
  const [step, setStep] = useState(1);
  const finishTourMutation = useFinishTourMutation();
  const createNpsResponseMutation = useCreateNpsResponseMutation();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const getScoreColor = (currentScore: number) => {
    if (currentScore <= 6) return 'red';
    if (currentScore <= 8) return 'yellow';
    return 'green';
  };

  const getMoodIcon = (currentScore: number) => {
    if (currentScore <= 6) return <IconMoodSad size={28} />;
    if (currentScore <= 8) return <IconMoodSmile size={28} />;
    return <IconMoodHappy size={28} />;
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (!score) return;

    const numericScore = parseInt(score);
    createNpsResponseMutation.mutate(
      {
        score: numericScore,
        feedback: feedback || undefined,
      },
      {
        onSuccess: () => {
          finishTourMutation.mutate(NPS_TOUR_KEY, {
            onSuccess: () => {
              onClose();
            },
          });
        },
      },
    );
  };

  const progress = step === 1 ? 50 : 100;

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<Title order={2}>{t('nps.title')}</Title>}
      size="xl"
      centered
      radius="md"
      padding="xl"
      overlayProps={{
        blur: 3,
        opacity: 0.55,
      }}
    >
      <Stack gap="xl">
        <Progress value={progress} size="sm" animated />

        <AnimatePresence mode="wait">
          {step === 1 ? (
            <MotionStack
              key="step1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              gap="xl"
            >
              <Stack gap="xs">
                <Text size="xl" fw={500}>
                  {t('nps.question')}
                </Text>
                <Text size="sm" c="dimmed">
                  {t('nps.description')}
                </Text>
              </Stack>

              {!isMobile ? (
                <Box>
                  <ScrollArea>
                    <Flex
                      gap="xs"
                      justify="space-between"
                      align="center"
                      w="100%"
                      px="xs"
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                        <motion.div
                          key={value}
                          variants={numberVariants}
                          whileHover={{
                            scale: 1.1,
                            transition: {
                              type: 'spring',
                              stiffness: 400,
                              damping: 10,
                            },
                          }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <RadioButton
                            value={value}
                            checked={score === value.toString()}
                            onChange={setScore}
                            color={getScoreColor(value)}
                          />
                        </motion.div>
                      ))}
                    </Flex>
                  </ScrollArea>
                </Box>
              ) : (
                <Stack gap="xs">
                  {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((value) => (
                    <MobileScoreButton
                      key={value}
                      value={value}
                      checked={score === value.toString()}
                      onChange={setScore}
                      color={getScoreColor(value)}
                    />
                  ))}
                </Stack>
              )}
            </MotionStack>
          ) : (
            <MotionStack
              key="step2"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
              gap="xl"
            >
              {score && (
                <Group gap="xs">
                  <ThemeIcon
                    size="xl"
                    variant="light"
                    color={getScoreColor(parseInt(score))}
                    radius="xl"
                  >
                    {getMoodIcon(parseInt(score))}
                  </ThemeIcon>
                  <Text size="xl" fw={500}>
                    {t('nps.feedbackLabel')}
                  </Text>
                </Group>
              )}

              <Textarea
                placeholder={t('nps.feedbackPlaceholder')}
                value={feedback}
                onChange={(event) => {
                  setFeedback(event.currentTarget.value);
                }}
                minRows={4}
                autoFocus
                styles={{
                  input: {
                    fontSize: theme.fontSizes.md,
                  },
                }}
              />
            </MotionStack>
          )}
        </AnimatePresence>

        <Group justify="flex-end" mt="xl">
          {step === 2 && (
            <Button
              variant="subtle"
              onClick={() => {
                setStep(1);
              }}
              color="gray"
            >
              {t('labels.back')}
            </Button>
          )}
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <Button
              onClick={handleNext}
              disabled={!score}
              size="md"
              rightSection={step === 2 && <IconSend size={18} />}
              variant="gradient"
              gradient={{ from: 'blue', to: 'violet' }}
            >
              {step === 1 ? t('labels.next') : t('labels.submit')}
            </Button>
          </motion.div>
        </Group>
      </Stack>
    </Modal>
  );
};

export { NPS_TOUR_KEY };
