import type { FontConfig } from './types';
import type { FontMetadata } from './font-types';

// Import all font configs
import dmSerifDisplayConfig from '../fonts/dm_serif_display';
import dmSansVariableConfig from '../fonts/dm_sans_variable';
import ibmPlexSansConfig from '../fonts/ibm_plex_sans';
import ibmPlexSerifConfig from '../fonts/ibm_plex_serif';
import interVariableConfig from '../fonts/inter_variable';
import latoConfig from '../fonts/lato';
import libreBaskerville from '../fonts/libre_baskerville';
import loraVariableConfig from '../fonts/lora_variable';
import merriweatherConfig from '../fonts/merriweather';
import montserratVariableConfig from '../fonts/montserrat_variable';
import nunitoVariableConfig from '../fonts/nunito_variable';
import openSansVariableConfig from '../fonts/open_sans_variable';
import oswaldVariableConfig from '../fonts/oswald_variable';
import playfairDisplayVariableConfig from '../fonts/playfair_display_variable';
import poppinsConfig from '../fonts/poppins';
import ptSansConfig from '../fonts/pt_sans';
import quicksandVariableConfig from '../fonts/quicksand_variable';
import ralewayVariableConfig from '../fonts/raleway_variable';
import robotoConfig from '../fonts/roboto';
import sourceSans3VariableConfig from '../fonts/source_sans_3_variable';
import splineSansVariableConfig from '../fonts/spline_sans_variable';
import titilliumWebConfig from '../fonts/titillium_web';
import ubuntuConfig from '../fonts/ubuntu';
import workSansVariableConfig from '../fonts/work_sans_variable';

const fontConfigs = [
  dmSerifDisplayConfig,
  dmSansVariableConfig,
  ibmPlexSansConfig,
  ibmPlexSerifConfig,
  interVariableConfig,
  latoConfig,
  libreBaskerville,
  loraVariableConfig,
  merriweatherConfig,
  montserratVariableConfig,
  nunitoVariableConfig,
  openSansVariableConfig,
  oswaldVariableConfig,
  playfairDisplayVariableConfig,
  poppinsConfig,
  ptSansConfig,
  quicksandVariableConfig,
  ralewayVariableConfig,
  robotoConfig,
  sourceSans3VariableConfig,
  splineSansVariableConfig,
  titilliumWebConfig,
  ubuntuConfig,
  workSansVariableConfig,
] satisfies FontConfig[];

// Dynamically build font metadata from configs
export const fontMetadata: Record<string, FontMetadata> = Object.fromEntries(
  fontConfigs.map((config: FontConfig) => [
    config.metadata.name,
    config.metadata,
  ]),
) as Record<string, FontMetadata>;
