import { Font } from '@react-pdf/renderer';
import { getLogger } from '../../services/Logger';
import { fontMetadata } from './font-metadata';
import { getFontNames } from './font-types';
import type { FontConfig } from './types';

const logger = getLogger('pdf/utils/fontRegistration');

// Keep track of registered fonts to avoid duplicate registration
const registeredFonts = new Set<string>();

// Import all font configurations at once using Vite's glob import
const fontModules = import.meta.glob<{ default: FontConfig }>('../fonts/*.ts', { eager: true });

export const registerPDFFonts = async (
  headerFont: string,
  textFont: string,
): Promise<void> => {
  const fontsToRegister = new Set([headerFont, textFont]);

  for (const fontName of fontsToRegister) {
    // Skip if already registered
    if (registeredFonts.has(fontName)) continue;

    const meta = fontMetadata[fontName];
    if (!meta) {
      logger.warn('Font metadata not found', { fontName });
      continue;
    }

    try {
      const names = getFontNames(fontName, fontMetadata);
      const modulePath = `../fonts/${names.packageName}.ts`;
      const fontConfig = fontModules[modulePath];

      if (!fontConfig) {
        throw new Error(`Font configuration not found for ${fontName}`);
      }

      // Register all font variants from the config
      Font.register({
        family: names.pdfName, // Use the PDF name which includes "Variable" suffix if needed
        fonts: fontConfig.default.fonts,
      });

      // Mark as registered
      registeredFonts.add(fontName);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      logger.error('Failed to register font', {
        fontName,
        error: errorMessage,
      });
      throw new Error(`Failed to register font ${fontName}: ${errorMessage}`);
    }
  }
};
