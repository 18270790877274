import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { Section, SectionProps } from '../layout/Section';
import { Heading } from '../typography/Heading';
import { Text } from '../typography/Text';

export interface Education {
  schoolName: string;
  degree: string;
  fieldOfStudy: string;
  startDate: string;
  endDate?: string;
}

export interface EducationSectionProps extends SectionProps {
  title?: string;
  educations: Education[];
  presentText?: string;
}

export const EducationSection: React.FC<EducationSectionProps> = ({
  theme,
  title = 'Education',
  educations,
  presentText = 'Present',
  ...sectionProps
}) => {
  const styles = StyleSheet.create({
    educationItem: {
      marginBottom: theme.spacing.md,
    },
    school: {
      marginBottom: theme.spacing.xs,
      marginTop: theme.spacing.xs,
    },
    degree: {
      marginBottom: theme.spacing.xs,
      lineHeight: 1.2,
    },
  });

  return (
    <Section theme={theme} {...sectionProps}>
      <Heading theme={theme} level={2}>
        {title}
      </Heading>
      {educations.map((edu, index) => (
        <View key={index} style={styles.educationItem}>
          <Heading theme={theme} level={3} style={styles.degree}>
            {edu.degree}
          </Heading>
          <Text theme={theme} size="h3" style={styles.degree} weight={500}>
            {edu.fieldOfStudy}
          </Text>
          <Text
            theme={theme}
            size="small"
            style={{ marginTop: theme.spacing.sm }}
          >
            {edu.startDate} - {edu.endDate || presentText}
          </Text>
          <Text theme={theme} style={styles.school}>
            {edu.schoolName}
          </Text>
        </View>
      ))}
    </Section>
  );
};
