import metadata from './spline_sans_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import spline_sans_variableUrlnormal300 from './ttf/spline_sans_variable/spline_sans_variable-normal-300.ttf?url';
import spline_sans_variableUrlnormal400 from './ttf/spline_sans_variable/spline_sans_variable-normal-400.ttf?url';
import spline_sans_variableUrlnormal500 from './ttf/spline_sans_variable/spline_sans_variable-normal-500.ttf?url';
import spline_sans_variableUrlnormal600 from './ttf/spline_sans_variable/spline_sans_variable-normal-600.ttf?url';
import spline_sans_variableUrlnormal700 from './ttf/spline_sans_variable/spline_sans_variable-normal-700.ttf?url';

const fontConfig: FontConfig = {
  family: 'Spline Sans',
  fonts: [
    {
      src: spline_sans_variableUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: spline_sans_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: spline_sans_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: spline_sans_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: spline_sans_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
