import metadata from './merriweather.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import merriweatherUrlitalic300 from './ttf/merriweather/merriweather-italic-300.ttf?url';
import merriweatherUrlitalic400 from './ttf/merriweather/merriweather-italic-400.ttf?url';
import merriweatherUrlitalic700 from './ttf/merriweather/merriweather-italic-700.ttf?url';
import merriweatherUrlitalic900 from './ttf/merriweather/merriweather-italic-900.ttf?url';
import merriweatherUrlnormal300 from './ttf/merriweather/merriweather-normal-300.ttf?url';
import merriweatherUrlnormal400 from './ttf/merriweather/merriweather-normal-400.ttf?url';
import merriweatherUrlnormal700 from './ttf/merriweather/merriweather-normal-700.ttf?url';
import merriweatherUrlnormal900 from './ttf/merriweather/merriweather-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Merriweather',
  fonts: [
    {
      src: merriweatherUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: merriweatherUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: merriweatherUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: merriweatherUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: merriweatherUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: merriweatherUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: merriweatherUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: merriweatherUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
