import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';

export const formatPDFDate = (
  date: string | undefined,
  language: string,
  format: string = 'MMM YYYY',
): string => {
  if (!date) return '';

  // Map language codes to dayjs locales
  const localeMap: { [key: string]: string } = {
    en: 'en',
    de: 'de',
    fr: 'fr',
    es: 'es',
    it: 'it',
    pt: 'pt',
  };

  const locale = localeMap[language] || 'en';
  return dayjs(date).locale(locale).format(format);
};
