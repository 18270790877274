import i18next, { type TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { getLogger } from '../../services/Logger';
import resourcesToBackend from 'i18next-resources-to-backend';

const logger = getLogger('pdf/utils/translations');

// Create and initialize a single i18n instance
const i18nInstance = i18next.createInstance();

// Configure i18n instance with dynamic loading
i18nInstance
  .use(
    resourcesToBackend(
      (language: string) =>
        import(
          /* webpackChunkName: "pdf-[request]" */ `../../locales/${language}.json`
        ),
    ),
  )
  .init({
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  })
  .catch((error) => {
    logger.error('Error initializing i18n:', error);
  });

interface UsePDFTranslationReturn {
  t: TFunction;
  i18n: typeof i18nInstance;
  ready: boolean;
}

/**
 * Hook for using translations in PDF components
 * @param language - The language to use for translations
 * @returns Object containing translation function (t), i18n instance, and ready state
 */
export const usePDFTranslation = (
  language: string,
): UsePDFTranslationReturn => {
  const [isReady, setIsReady] = useState(i18nInstance.isInitialized);

  useEffect(() => {
    void i18nInstance
      .changeLanguage(language)
      .then(() => setIsReady(true))
      .catch((error) => {
        logger.error('Error changing language:', error);
        setIsReady(false);
      });
  }, [language]);

  return {
    t: i18nInstance.t.bind(i18nInstance),
    i18n: i18nInstance,
    ready: isReady,
  };
};

// Keep the direct function for non-React contexts, but now it returns a Promise
export const getPDFTranslation = async (
  key: string,
  language: string,
): Promise<string> => {
  try {
    await i18nInstance.changeLanguage(language);
    return i18nInstance.t(key);
  } catch (error) {
    logger.error('Error getting translation:', error);
    return key;
  }
};
