import metadata from './titillium_web.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import titillium_webUrlitalic200 from './ttf/titillium_web/titillium_web-italic-200.ttf?url';
import titillium_webUrlitalic300 from './ttf/titillium_web/titillium_web-italic-300.ttf?url';
import titillium_webUrlitalic400 from './ttf/titillium_web/titillium_web-italic-400.ttf?url';
import titillium_webUrlitalic600 from './ttf/titillium_web/titillium_web-italic-600.ttf?url';
import titillium_webUrlitalic700 from './ttf/titillium_web/titillium_web-italic-700.ttf?url';
import titillium_webUrlnormal200 from './ttf/titillium_web/titillium_web-normal-200.ttf?url';
import titillium_webUrlnormal300 from './ttf/titillium_web/titillium_web-normal-300.ttf?url';
import titillium_webUrlnormal400 from './ttf/titillium_web/titillium_web-normal-400.ttf?url';
import titillium_webUrlnormal600 from './ttf/titillium_web/titillium_web-normal-600.ttf?url';
import titillium_webUrlnormal700 from './ttf/titillium_web/titillium_web-normal-700.ttf?url';
import titillium_webUrlnormal900 from './ttf/titillium_web/titillium_web-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Titillium Web',
  fonts: [
    {
      src: titillium_webUrlitalic200,
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: titillium_webUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: titillium_webUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: titillium_webUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: titillium_webUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: titillium_webUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: titillium_webUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: titillium_webUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: titillium_webUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: titillium_webUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: titillium_webUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
