import metadata from './poppins.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import poppinsUrlitalic100 from './ttf/poppins/poppins-italic-100.ttf?url';
import poppinsUrlitalic200 from './ttf/poppins/poppins-italic-200.ttf?url';
import poppinsUrlitalic300 from './ttf/poppins/poppins-italic-300.ttf?url';
import poppinsUrlitalic400 from './ttf/poppins/poppins-italic-400.ttf?url';
import poppinsUrlitalic500 from './ttf/poppins/poppins-italic-500.ttf?url';
import poppinsUrlitalic600 from './ttf/poppins/poppins-italic-600.ttf?url';
import poppinsUrlitalic700 from './ttf/poppins/poppins-italic-700.ttf?url';
import poppinsUrlitalic800 from './ttf/poppins/poppins-italic-800.ttf?url';
import poppinsUrlitalic900 from './ttf/poppins/poppins-italic-900.ttf?url';
import poppinsUrlnormal100 from './ttf/poppins/poppins-normal-100.ttf?url';
import poppinsUrlnormal200 from './ttf/poppins/poppins-normal-200.ttf?url';
import poppinsUrlnormal300 from './ttf/poppins/poppins-normal-300.ttf?url';
import poppinsUrlnormal400 from './ttf/poppins/poppins-normal-400.ttf?url';
import poppinsUrlnormal500 from './ttf/poppins/poppins-normal-500.ttf?url';
import poppinsUrlnormal600 from './ttf/poppins/poppins-normal-600.ttf?url';
import poppinsUrlnormal700 from './ttf/poppins/poppins-normal-700.ttf?url';
import poppinsUrlnormal800 from './ttf/poppins/poppins-normal-800.ttf?url';
import poppinsUrlnormal900 from './ttf/poppins/poppins-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Poppins',
  fonts: [
    {
      src: poppinsUrlitalic100,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: poppinsUrlitalic200,
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: poppinsUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: poppinsUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: poppinsUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: poppinsUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: poppinsUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: poppinsUrlitalic800,
      fontStyle: 'italic',
      fontWeight: 800,
    },
    {
      src: poppinsUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: poppinsUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: poppinsUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: poppinsUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: poppinsUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: poppinsUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: poppinsUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: poppinsUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: poppinsUrlnormal800,
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: poppinsUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
