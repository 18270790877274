import metadata from './playfair_display_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import playfair_display_variableUrlitalic400 from './ttf/playfair_display_variable/playfair_display_variable-italic-400.ttf?url';
import playfair_display_variableUrlitalic500 from './ttf/playfair_display_variable/playfair_display_variable-italic-500.ttf?url';
import playfair_display_variableUrlitalic600 from './ttf/playfair_display_variable/playfair_display_variable-italic-600.ttf?url';
import playfair_display_variableUrlitalic700 from './ttf/playfair_display_variable/playfair_display_variable-italic-700.ttf?url';
import playfair_display_variableUrlitalic800 from './ttf/playfair_display_variable/playfair_display_variable-italic-800.ttf?url';
import playfair_display_variableUrlitalic900 from './ttf/playfair_display_variable/playfair_display_variable-italic-900.ttf?url';
import playfair_display_variableUrlnormal400 from './ttf/playfair_display_variable/playfair_display_variable-normal-400.ttf?url';
import playfair_display_variableUrlnormal500 from './ttf/playfair_display_variable/playfair_display_variable-normal-500.ttf?url';
import playfair_display_variableUrlnormal600 from './ttf/playfair_display_variable/playfair_display_variable-normal-600.ttf?url';
import playfair_display_variableUrlnormal700 from './ttf/playfair_display_variable/playfair_display_variable-normal-700.ttf?url';
import playfair_display_variableUrlnormal800 from './ttf/playfair_display_variable/playfair_display_variable-normal-800.ttf?url';
import playfair_display_variableUrlnormal900 from './ttf/playfair_display_variable/playfair_display_variable-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Playfair Display',
  fonts: [
    {
      src: playfair_display_variableUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: playfair_display_variableUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: playfair_display_variableUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: playfair_display_variableUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: playfair_display_variableUrlitalic800,
      fontStyle: 'italic',
      fontWeight: 800,
    },
    {
      src: playfair_display_variableUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: playfair_display_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: playfair_display_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: playfair_display_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: playfair_display_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: playfair_display_variableUrlnormal800,
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: playfair_display_variableUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
