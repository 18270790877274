import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';
import { IResume, IResumeJSON, ResumeStatus } from '../models/Resume';

export type IUpdateResumeDto = Pick<
  IResume,
  'title' | 'info' | 'jobAdType' | 'ad' | 'jobAdUrl' | 'language' | 'style'
>;

export type IUpdateResumeJsonDto = IResumeJSON;

export const QUERY_KEY_RESUMES = ['resumes'];

export const useCreateResumeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<IResume>) => {
      const response = await Api.ky.post(ApiRoutes.Resumes, { json: data });
      return response.json<IResume>();
    },
    onSuccess: (data) => {
      queryClient.setQueryData([...QUERY_KEY_RESUMES, data.id], data);
    },
  });
};

export const useUpdateResumeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: { id: string; data: Partial<IResume> }) => {
      const response = await Api.ky.put(`${ApiRoutes.Resumes}/${params.id}`, {
        json: params.data,
      });
      return response.json<IResume>();
    },
    onSuccess: (data) => {
      queryClient.setQueryData([...QUERY_KEY_RESUMES, data.id], data);
    },
  });
};

export const useUpdateResumeJsonMutation = (resumeId: IResume['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updatedResumeJson: IUpdateResumeJsonDto) =>
      Api.ky
        .put(`${ApiRoutes.Resumes}/${resumeId}/json`, {
          json: { suggestion: updatedResumeJson },
        })
        .json<IResume>(),
    onSuccess: (data) =>
      queryClient.setQueryData([...QUERY_KEY_RESUMES, resumeId], data),
  });
};

export const useSuggestionMutation = (resumeId: IResume['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      Api.ky
        .post(`${ApiRoutes.Resumes}/${resumeId}/new-suggestion`)
        .json<IResume>(),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_RESUMES,
      }),
  });
};

export const useResumeQuery = (id?: string) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [...QUERY_KEY_RESUMES, id],
    queryFn: async () => {
      if (!id) return null;
      const response = await Api.ky.get(`${ApiRoutes.Resumes}/${id}`);
      return response.json<IResume>();
    },
    enabled: !!id,
    // Poll only when actually generating or preparing
    refetchInterval: (query) => {
      const data = query.state.data as IResume | null;
      return data?.status === ResumeStatus.Preparing ||
        data?.status === ResumeStatus.Generating
        ? 2000
        : false;
    },
    retry: (failureCount) => {
      const data = queryClient.getQueryData([
        ...QUERY_KEY_RESUMES,
        id,
      ]) as IResume | null;
      if (
        data?.status === ResumeStatus.Generating ||
        data?.status === ResumeStatus.Preparing
      ) {
        return failureCount < 5;
      }
      return failureCount < 3;
    },
    retryDelay: 1000,
  });
};

export interface IPageMeta {
  readonly page: number;
  readonly take: number;
  readonly itemCount: number;
  readonly pageCount: number;
  readonly hasPreviousPage: boolean;
  readonly hasNextPage: boolean;
}

export interface IPageOptions {
  page: number;
  take: number;
  order?: 'ASC' | 'DESC';
  q?: string;
}

export const RECORDS_PER_PAGE = 10;

export const useResumesQuery = (options: Partial<IPageOptions> = {}) => {
  const { page = 1, take = RECORDS_PER_PAGE, order = 'DESC', q } = options;

  return useQuery({
    queryKey: [
      ...QUERY_KEY_RESUMES,
      `p${page}`,
      `t${take}`,
      `o${order}`,
      `q${q || ''}`,
    ],
    queryFn: () => {
      const searchParams = new URLSearchParams({
        page: page.toString(),
        take: take.toString(),
        order,
      });

      if (q) {
        searchParams.append('q', q);
      }

      return Api.ky
        .get(`${ApiRoutes.Resumes}?${searchParams.toString()}`)
        .json<{ items: IResume[]; meta: IPageMeta }>()
        .then(({ items, meta }) => ({
          resumes: items.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          ),
          meta,
        }));
    },
  });
};

export const useParseResumeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (resumeId: string) => {
      await Api.ky.post(`${ApiRoutes.Resumes}/${resumeId}/parse`);
      // Immediately fetch the updated resume to get the new status
      const response = await Api.ky.get(`${ApiRoutes.Resumes}/${resumeId}`);
      return response.json<IResume>();
    },
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY_RESUMES, data.id], data);
    },
  });
};

export const useGenerateResumeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (resumeId: string) => {
      await Api.ky.post(`${ApiRoutes.Resumes}/${resumeId}/generate`);
      return resumeId;
    },
    onSuccess: (resumeId) => {
      // Wait a short moment for the backend to start processing
      void new Promise((resolve) => setTimeout(resolve, 1000))
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: [...QUERY_KEY_RESUMES, resumeId],
          }),
        )
        .catch(() => {
          // Ignore timeout errors
        });
    },
  });
};

export const useResumeCountQuery = () => {
  return useQuery({
    queryKey: [...QUERY_KEY_RESUMES, 'count'],
    queryFn: () =>
      Api.ky.get<{ count: number }>(ApiRoutes.Resumes + '/count').json(),
  });
};

interface ResumeActivity {
  date: string;
  count: number;
}

export const useResumeActivityQuery = () => {
  return useQuery<ResumeActivity[]>({
    queryKey: ['resumeActivity'],
    queryFn: async () =>
      Api.ky.get<ResumeActivity[]>(`${ApiRoutes.Resumes}/activity`).json(),
  });
};
