import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { PDFTheme } from '../../themes/types';
import { Text } from './Text';
import { Style } from '@react-pdf/types';

export interface BulletPointsProps {
  theme: PDFTheme;
  points: string[];
  style?: Style;
  addPageBreakPadding?: boolean;
}

export const BulletPoints: React.FC<BulletPointsProps> = ({
  theme,
  points,
  style = {},
  addPageBreakPadding = false,
}) => {
  const styles = StyleSheet.create({
    bulletList: {
      display: 'flex',
      flexDirection: 'column',
      ...style,
    },
    bulletPointContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing.xs,
      alignItems: 'flex-start',
      minHeight:
        theme.typography.sizes.body *
        theme.typography.textFont.recommendedLineHeight,
    },
    bulletPointSymbol: {
      width: 12,
      marginRight: theme.spacing.xs,
    },
    bulletPointText: {
      flex: 1,
      display: 'flex',
      flexWrap: 'wrap',
    },
  });

  if (!Array.isArray(points)) {
    return null;
  }

  console.log(
    'theme.typography.textFont.recommendedLineHeight',
    theme.typography.textFont.recommendedLineHeight,
  );

  return (
    <View style={styles.bulletList} wrap>
      {points.map((point, index) => (
        <View key={index} style={styles.bulletPointContainer} wrap={false}>
          <Text theme={theme} style={styles.bulletPointSymbol}>
            •
          </Text>
          <Text theme={theme} style={styles.bulletPointText}>
            {point}
          </Text>
        </View>
      ))}
      {addPageBreakPadding && <View fixed style={{ height: 20 }} />}
    </View>
  );
};

export const BulletPointsWithPadding: React.FC<
  Omit<BulletPointsProps, 'addPageBreakPadding'>
> = (props) => <BulletPoints {...props} addPageBreakPadding />;
