import React, { PropsWithChildren } from 'react';
import { View } from '@react-pdf/renderer';
import { PDFTheme } from '../../themes/types';
import { Style } from '@react-pdf/types';

export interface SectionProps extends PropsWithChildren {
  theme: PDFTheme;
  spacing?: keyof PDFTheme['spacing'];
  style?: Style;
  fixed?: boolean;
  wrap?: boolean;
  background?: boolean;
  padding?: keyof PDFTheme['spacing'] | false;
  minPresenceAhead?: number;
}

export const Section: React.FC<SectionProps> = ({
  children,
  theme,
  spacing = 'lg',
  style = {},
  fixed = false,
  wrap = true,
  background = true,
  padding = 'md',
  minPresenceAhead,
}) => {
  const baseStyle: Style = {
    marginBottom: theme.spacing[spacing],
    ...(padding && { padding: theme.spacing[padding] }),
    ...(background && { backgroundColor: theme.colors.surface }),
    ...style,
  };

  return (
    <View
      fixed={fixed}
      wrap={wrap}
      style={baseStyle}
      minPresenceAhead={minPresenceAhead}
    >
      {children}
    </View>
  );
};
