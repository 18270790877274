import { PDFTheme } from '../types';
import { Font } from '../../utils/Font';

export const defaultTheme: PDFTheme = {
  colors: {
    primary: '#2F7D7A',
    secondary: '#455A64',
    text: '#2C3E50',
    background: '#FFFFFF',
    surface: '#F5F7FA',
    accent: '#3498DB',
    muted: '#95A5A6',
  },
  typography: {
    headerFont: new Font('Nunito'),
    textFont: new Font('Roboto'),
    sizes: {
      h1: 28,
      h2: 20,
      h3: 16,
      body: 12,
      small: 10,
    },
  },
  spacing: {
    xs: 4,
    sm: 8,
    md: 16,
    lg: 24,
    xl: 32,
  },
  pageSize: 'A4',
};
