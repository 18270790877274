import React, { PropsWithChildren } from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import { PDFTheme } from '../../themes/types';
import { Style } from '@react-pdf/types';
import { FontStyle, FontWeight } from '@react-pdf/types';

export interface HeadingProps extends PropsWithChildren {
  theme: PDFTheme;
  level?: 1 | 2 | 3;
  style?: Style;
  color?: string;
  weight?: FontWeight;
  fontStyle?: FontStyle;
}

export const Heading: React.FC<HeadingProps> = ({
  children,
  theme,
  level = 1,
  style = {},
  color,
  weight,
  fontStyle,
}) => {
  const styles = StyleSheet.create({
    heading: {
      ...theme.typography.headerFont.getStyleForHeading(level, {
        weight,
        style: fontStyle,
      }),
      // fontSize: theme.typography.sizes[`h${level}`],
      color: color || theme.colors.primary,
      marginBottom: theme.spacing.sm,
      ...style,
    },
  });

  return <Text style={styles.heading}>{children}</Text>;
};
