import metadata from './pt_sans.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import pt_sansUrlitalic400 from './ttf/pt_sans/pt_sans-italic-400.ttf?url';
import pt_sansUrlitalic700 from './ttf/pt_sans/pt_sans-italic-700.ttf?url';
import pt_sansUrlnormal400 from './ttf/pt_sans/pt_sans-normal-400.ttf?url';
import pt_sansUrlnormal700 from './ttf/pt_sans/pt_sans-normal-700.ttf?url';

const fontConfig: FontConfig = {
  family: 'PT Sans',
  fonts: [
    {
      src: pt_sansUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: pt_sansUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: pt_sansUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: pt_sansUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
