import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { PDFProps } from '../../PDFProps';
import { BaseTemplate } from '../../core/BaseTemplate';
import { Grid } from '../../components/layout/Grid';
import { Section } from '../../components/layout/Section';
import { Heading } from '../../components/typography/Heading';
import { Text } from '../../components/typography/Text';
import { ExperienceSection } from '../../components/sections/ExperienceSection';
import { SkillsSection } from '../../components/sections/SkillsSection';
import { EducationSection } from '../../components/sections/EducationSection';
import { defaultTheme } from '../../themes/presets/default';
import { PDFTheme } from '../../themes/types';
import { usePDFTranslation } from '../../utils/translations';
import { formatPDFDate } from '../../utils/dates';
import { Font } from '../../utils/Font';

export const ModernTemplate: React.FC<PDFProps> = ({
  resume,
  user,
  language,
  title,
  primaryColor,
  headerFont = defaultTheme.typography.headerFont.name,
  textFont = defaultTheme.typography.textFont.name,
}) => {
  const { t } = usePDFTranslation(language);

  const theme: PDFTheme = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      primary: primaryColor || defaultTheme.colors.primary,
    },
    typography: {
      ...defaultTheme.typography,
      headerFont: new Font(headerFont),
      textFont: new Font(textFont),
      sizes: {
        ...defaultTheme.typography.sizes,
        h1: 24,
        h2: 16,
        h3: 14,
        body: 10,
        small: 9,
      },
    },
    spacing: {
      ...defaultTheme.spacing,
      xs: 3,
      sm: 6,
      md: 12,
      lg: 18,
      xl: 24,
    },
  };

  const styles = StyleSheet.create({
    header: {
      marginBottom: theme.spacing.md,
      textAlign: 'center',
      paddingHorizontal: theme.spacing.lg,
    },
    intro: {
      fontSize: theme.typography.sizes.body,
      marginTop: theme.spacing.xs,
      color: theme.colors.muted,
      maxWidth: '80%',
      alignSelf: 'center',
    },
    contactInfo: {
      fontSize: theme.typography.sizes.body,
      marginBottom: theme.spacing.xs,
    },
  });

  return (
    <BaseTemplate user={user} language={language} title={title} theme={theme}>
      <Section theme={theme} spacing="md" background={false}>
        <View style={styles.header}>
          <Heading theme={theme} level={1}>
            {user.profile?.firstName} {user.profile?.lastName}
          </Heading>
          <Text theme={theme} style={styles.intro}>
            {resume?.intro || t('resume.personalInformation')}
          </Text>
        </View>
      </Section>

      <Grid theme={theme} columnWidths={['30%', '70%']} gap="md">
        <View wrap style={{ marginBottom: theme.spacing.md }}>
          <Section theme={theme} wrap={false}>
            <Heading theme={theme} level={2}>
              {t('resume.contact')}
            </Heading>
            <Text theme={theme} style={styles.contactInfo}>
              {user.profile?.displayEmail || user.email}
            </Text>
            {user.profile?.phone && (
              <Text theme={theme} style={styles.contactInfo}>
                {user.profile.phone}
              </Text>
            )}
            {user.profile?.address && (
              <Text theme={theme} style={styles.contactInfo}>
                {user.profile.address}
              </Text>
            )}
          </Section>

          <SkillsSection
            theme={theme}
            title={t('resume.skills.title')}
            skills={resume.skills || []}
          />
        </View>

        <View wrap minPresenceAhead={100}>
          <ExperienceSection
            theme={theme}
            title={t('resume.experience.title')}
            experiences={
              resume.experiences?.map((exp) => ({
                company: exp.company.name,
                position: exp.title,
                startDate: formatPDFDate(exp.start, language),
                endDate: exp.end ? formatPDFDate(exp.end, language) : undefined,
                description: exp.summary || [],
              })) || []
            }
            minPresenceAhead={100}
          />

          <EducationSection
            theme={theme}
            title={t('resume.education.title')}
            educations={
              resume.educations?.map((edu) => ({
                schoolName: edu.schoolName,
                degree: edu.degree,
                fieldOfStudy: edu.fieldOfStudy,
                startDate: formatPDFDate(edu.start, language),
                endDate: edu.end ? formatPDFDate(edu.end, language) : undefined,
              })) || []
            }
            presentText={t('resume.present')}
            minPresenceAhead={100}
          />
        </View>
      </Grid>
    </BaseTemplate>
  );
};
