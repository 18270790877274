import { Button, Paper, Stack, Text, Title } from '@mantine/core';
import { IconFiles } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateEntry } from './ResumeDocumentView';

interface TemplateSelectorProps {
  currentTemplate: TemplateEntry;
  onOpenModal: () => void;
}

export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  currentTemplate,
  onOpenModal,
}) => {
  const { t } = useTranslation();

  return (
    <Paper p="sm">
      <Stack justify="space-between">
        <Stack gap={0}>
          <Title order={6} c="dimmed">
            {t('view.pdf.selectedTemplateTitle')}
          </Title>
          <Text>{currentTemplate.label}</Text>
        </Stack>
        <Button
          leftSection={<IconFiles />}
          onClick={onOpenModal}
          variant="light"
          size="xs"
        >
          {t('view.pdf.chooseTemplateButtonTitle')}
        </Button>
      </Stack>
    </Paper>
  );
};
