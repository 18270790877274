import { FontStyle, FontWeight } from '@react-pdf/types';
import { FontMetadata, FontNames, getFontNames } from './font-types';
import { fontMetadata } from './font-metadata';

export class Font {
  private metadata: FontMetadata;
  private names: FontNames;

  constructor(fontName: string) {
    this.metadata = fontMetadata[fontName];
    if (!this.metadata) {
      throw new Error(`Font ${fontName} not found in metadata`);
    }
    this.names = getFontNames(fontName, fontMetadata);
  }

  get name(): string {
    return this.metadata.name;
  }

  get pdfName(): string {
    return this.names.pdfName;
  }

  get uiName(): string {
    return this.names.uiName;
  }

  get packageName(): string {
    return this.names.packageName;
  }

  get category(): string {
    return this.metadata.category;
  }

  get isVariable(): boolean {
    return this.metadata.variable;
  }

  get availableWeights(): FontWeight[] {
    return this.metadata.weights;
  }

  get availableStyles(): FontStyle[] {
    return this.metadata.styles;
  }

  getRecommendedHeadingSize(level: number): number {
    const sizes = this.metadata.styleGuide.recommendedSizes.headings;
    const index = Math.max(0, sizes.length - level);
    return sizes[index];
  }

  getRecommendedBodySize(): number {
    return this.metadata.styleGuide.recommendedSizes.body[0];
  }

  getRecommendedHeadingWeight(level = 1): FontWeight {
    const weights = this.metadata.styleGuide.recommendedWeights.headings;
    const index = Math.max(0, weights.length - level);
    return weights[index];
  }

  getRecommendedBodyWeight(): FontWeight {
    return this.metadata.styleGuide.recommendedWeights.body[0];
  }

  get recommendedLineHeight(): number {
    return this.metadata.styleGuide.recommendedLineHeight;
  }

  get recommendedLetterSpacing(): number {
    return this.metadata.styleGuide.recommendedLetterSpacing;
  }

  // New methods for handling weights and styles
  getClosestWeight(targetWeight: FontWeight): FontWeight {
    if (this.availableWeights.includes(targetWeight)) {
      return targetWeight;
    }
    // Find the closest available weight
    return this.availableWeights.reduce((prev, curr) => {
      const prevDiff = Math.abs(Number(prev) - Number(targetWeight));
      const currDiff = Math.abs(Number(curr) - Number(targetWeight));
      return currDiff < prevDiff ? curr : prev;
    });
  }

  isStyleAvailable(style: FontStyle): boolean {
    return this.availableStyles.includes(style);
  }

  getStyleForHeading(
    level: number,
    options: { weight?: FontWeight; style?: FontStyle } = {},
  ) {
    const baseWeight =
      options.weight || this.getRecommendedHeadingWeight(level);
    const actualWeight = this.getClosestWeight(baseWeight);
    const style = this.isStyleAvailable(options.style || 'normal')
      ? options.style
      : 'normal';

    return {
      fontFamily: this.pdfName,
      fontSize: Math.round(this.getRecommendedHeadingSize(level) / 1.5),
      fontWeight: actualWeight,
      fontStyle: style,
      lineHeight: this.recommendedLineHeight,
      letterSpacing: this.recommendedLetterSpacing,
    };
  }

  getStyleForBody(options: { weight?: FontWeight; style?: FontStyle } = {}) {
    const baseWeight = options.weight || this.getRecommendedBodyWeight();
    const actualWeight = this.getClosestWeight(baseWeight);
    const style = this.isStyleAvailable(options.style || 'normal')
      ? options.style
      : 'normal';

    return {
      fontFamily: this.pdfName,
      fontSize: Math.round(this.getRecommendedBodySize() / 1.5),
      fontWeight: actualWeight,
      fontStyle: style,
      lineHeight: this.recommendedLineHeight,
      letterSpacing: this.recommendedLetterSpacing,
    };
  }
}
