import metadata from './roboto.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import robotoUrlitalic100 from './ttf/roboto/roboto-italic-100.ttf?url';
import robotoUrlitalic300 from './ttf/roboto/roboto-italic-300.ttf?url';
import robotoUrlitalic400 from './ttf/roboto/roboto-italic-400.ttf?url';
import robotoUrlitalic500 from './ttf/roboto/roboto-italic-500.ttf?url';
import robotoUrlitalic700 from './ttf/roboto/roboto-italic-700.ttf?url';
import robotoUrlitalic900 from './ttf/roboto/roboto-italic-900.ttf?url';
import robotoUrlnormal100 from './ttf/roboto/roboto-normal-100.ttf?url';
import robotoUrlnormal300 from './ttf/roboto/roboto-normal-300.ttf?url';
import robotoUrlnormal400 from './ttf/roboto/roboto-normal-400.ttf?url';
import robotoUrlnormal500 from './ttf/roboto/roboto-normal-500.ttf?url';
import robotoUrlnormal700 from './ttf/roboto/roboto-normal-700.ttf?url';
import robotoUrlnormal900 from './ttf/roboto/roboto-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Roboto',
  fonts: [
    {
      src: robotoUrlitalic100,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: robotoUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: robotoUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: robotoUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: robotoUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: robotoUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: robotoUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: robotoUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: robotoUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: robotoUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: robotoUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: robotoUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
