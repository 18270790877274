import metadata from './oswald_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import oswald_variableUrlnormal200 from './ttf/oswald_variable/oswald_variable-normal-200.ttf?url';
import oswald_variableUrlnormal300 from './ttf/oswald_variable/oswald_variable-normal-300.ttf?url';
import oswald_variableUrlnormal400 from './ttf/oswald_variable/oswald_variable-normal-400.ttf?url';
import oswald_variableUrlnormal500 from './ttf/oswald_variable/oswald_variable-normal-500.ttf?url';
import oswald_variableUrlnormal600 from './ttf/oswald_variable/oswald_variable-normal-600.ttf?url';
import oswald_variableUrlnormal700 from './ttf/oswald_variable/oswald_variable-normal-700.ttf?url';

const fontConfig: FontConfig = {
  family: 'Oswald',
  fonts: [
    {
      src: oswald_variableUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: oswald_variableUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: oswald_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: oswald_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: oswald_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: oswald_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
