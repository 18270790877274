import type { FontStyle, FontWeight } from '@react-pdf/types';

import { UI_LANGUAGES } from '../../Config';

export type FontCategory =
  | 'serif'
  | 'sans-serif'
  | 'display'
  | 'modern'
  | 'creative'
  | 'classic';

type Language = (typeof UI_LANGUAGES)[number];

export interface FontMetadata {
  name: string;
  category: FontCategory;
  weights: FontWeight[];
  styles: FontStyle[];
  variable: boolean;
  description: Record<Language, string>;
  recommendations: string[];
  alternativePairings: string[];
  useCases: string[];
  styleGuide: {
    recommendedSizes: {
      headings: number[];
      body: number[];
    };
    recommendedWeights: {
      headings: number[];
      body: number[];
    };
    recommendedLineHeight: number;
    recommendedLetterSpacing: number;
  };
}

export interface FontCombination {
  id: string;
  name: string;
  headerFont: string;
  headerFontUI: string;
  textFont: string;
  textFontUI: string;
  category: FontCategory;
  description: Record<Language, string>;
  sampleText: {
    heading: string;
    body: string;
  };
}

// Font name mapping for different contexts
export interface FontNames {
  // Name used in the UI (e.g. "Open Sans Variable")
  uiName: string;
  // Name used in PDF rendering (e.g. "Open Sans")
  pdfName: string;
  // Name used for font loading (e.g. "open-sans")
  packageName: string;
}

// Helper to get font names for different contexts
export const getFontNames = (
  fontName: string,
  metadata: Record<string, FontMetadata>,
): FontNames => {
  const fontMetadata = metadata[fontName];
  if (!fontMetadata) {
    throw new Error(`Font ${fontName} not found in metadata`);
  }

  const isVariable = fontMetadata.variable;
  const basePackageName =
    fontName.toLowerCase().replace(/\s+/g, '_') +
    (isVariable ? '_variable' : '');

  return {
    uiName: isVariable ? `${fontName} Variable` : fontName,
    pdfName: fontName,
    packageName: basePackageName,
  };
};

export type FontMode = 'popular' | 'custom';

export interface DocumentStyle {
  templateName: string;
  coverLetterTemplateName: string;
  primaryColor: string;
  pageSize: string;
  headerFont: string;
  textFont: string;
  documentType: DocumentType;
  fontMode: FontMode;
}
