import React, { PropsWithChildren } from 'react';
import { Text as PDFText, StyleSheet } from '@react-pdf/renderer';
import { PDFTheme } from '../../themes/types';
import { Style } from '@react-pdf/types';
import { FontStyle, FontWeight } from '@react-pdf/types';

export interface TextProps extends PropsWithChildren {
  theme: PDFTheme;
  style?: Style;
  color?: string;
  size?: keyof PDFTheme['typography']['sizes'];
  weight?: FontWeight;
  fontStyle?: FontStyle;
}

export const Text: React.FC<TextProps> = ({
  children,
  theme,
  style = {},
  color,
  size,
  weight,
  fontStyle = 'normal',
}) => {
  const styles = StyleSheet.create({
    text: {
      ...theme.typography.textFont.getStyleForBody({
        weight,
        style: fontStyle,
      }),
      ...(size && { fontSize: theme.typography.sizes[size] }),
      color: color || theme.colors.text,
      ...style,
    },
  });

  return <PDFText style={styles.text}>{children}</PDFText>;
};
