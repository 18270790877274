import metadata from './dm_serif_display.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import dm_serif_displayUrlitalic400 from './ttf/dm_serif_display/dm_serif_display-italic-400.ttf?url';
import dm_serif_displayUrlnormal400 from './ttf/dm_serif_display/dm_serif_display-normal-400.ttf?url';

const fontConfig: FontConfig = {
  family: 'DM Serif Display',
  fonts: [
    {
      src: dm_serif_displayUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: dm_serif_displayUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
