import {
  Box,
  Card,
  Combobox,
  Group,
  Input,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
  rem,
  useCombobox,
} from '@mantine/core';
import {
  IconInfoCircle,
  IconSearch,
  IconTypography,
} from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Font } from '../../pdf/utils/Font';
import { fontMetadata } from '../../pdf/utils/font-metadata';
import { FontInfoDialog } from './FontInfoDialog';

interface CustomFontCombinationProps {
  onSelect: (headerFont: string, textFont: string) => void;
  initialHeaderFont?: string;
  initialTextFont?: string;
}

export function CustomFontCombination({
  onSelect,
  initialHeaderFont,
  initialTextFont,
}: CustomFontCombinationProps) {
  const { t } = useTranslation();
  const [headerFont, setHeaderFont] = useState<Font | null>(() => {
    if (initialHeaderFont) {
      try {
        return new Font(initialHeaderFont);
      } catch {
        return null;
      }
    }
    return null;
  });

  const [textFont, setTextFont] = useState<Font | null>(() => {
    if (initialTextFont) {
      try {
        return new Font(initialTextFont);
      } catch {
        return null;
      }
    }
    return null;
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedPreview, setSelectedPreview] = useState<
    'header' | 'text' | null
  >(null);
  const [searchValue, setSearchValue] = useState('');

  const handleFontSelect = (font: Font, type: 'header' | 'text') => {
    if (type === 'header') {
      setHeaderFont(font);
      if (textFont) {
        onSelect(font.name, textFont.name);
      }
    } else {
      setTextFont(font);
      if (headerFont) {
        onSelect(headerFont.name, font.name);
      }
    }
  };

  const fonts = Object.values(fontMetadata).map(
    (metadata) => new Font(metadata.name),
  );

  const filteredFonts = fonts.filter((font) =>
    font.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const headerCombobox = useCombobox({
    onDropdownClose: () => {
      setSearchValue('');
    },
  });
  const textCombobox = useCombobox({
    onDropdownClose: () => {
      setSearchValue('');
    },
  });

  const renderFontOption = (font: Font) => (
    <Combobox.Option value={font.name} key={font.name}>
      <Group gap="sm">
        <Text
          style={{
            fontFamily: font.uiName,
            fontWeight: font.getRecommendedHeadingWeight(),
            letterSpacing: font.recommendedLetterSpacing,
          }}
        >
          {font.name}
        </Text>
        <Text size="xs" c="dimmed">
          {font.category} {font.isVariable ? '• Variable' : ''}
        </Text>
      </Group>
    </Combobox.Option>
  );

  const renderSelectedFont = (font: Font | null, type: 'header' | 'text') => {
    if (!font) return null;

    return (
      <Card
        shadow="sm"
        padding="md"
        radius="md"
        withBorder
        onClick={() => {
          if (type === 'header') {
            headerCombobox.openDropdown();
          } else {
            textCombobox.openDropdown();
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <Stack gap="xs">
          <Group justify="space-between" align="center">
            <Group gap="xs">
              <ThemeIcon variant="light" size="sm" color="blue">
                <IconTypography style={{ width: rem(14), height: rem(14) }} />
              </ThemeIcon>
              <Text size="sm" fw={500}>
                {type === 'header'
                  ? t('view.pdf.customFonts.headerFont')
                  : t('view.pdf.customFonts.textFont')}
              </Text>
            </Group>
            <ThemeIcon
              variant="light"
              size="sm"
              color="blue"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPreview(type);
                setIsDialogOpen(true);
              }}
            >
              <IconInfoCircle style={{ width: rem(14), height: rem(14) }} />
            </ThemeIcon>
          </Group>

          <Box>
            <Text
              size={type === 'header' ? 'xl' : 'lg'}
              style={{
                fontFamily: font.uiName,
                fontWeight:
                  type === 'header'
                    ? font.getRecommendedHeadingWeight()
                    : font.getRecommendedBodyWeight(),
                letterSpacing: font.recommendedLetterSpacing,
                lineHeight: font.recommendedLineHeight,
              }}
            >
              {font.name}
            </Text>
            <Text size="xs" c="dimmed" mt={4}>
              {font.category} {font.isVariable ? '• Variable' : ''}
            </Text>
          </Box>
        </Stack>
      </Card>
    );
  };

  const renderFontSelector = (type: 'header' | 'text') => {
    const combobox = type === 'header' ? headerCombobox : textCombobox;
    const selectedFont = type === 'header' ? headerFont : textFont;

    return (
      <Combobox
        store={combobox}
        onOptionSubmit={(value) => {
          const font = fonts.find((f) => f.name === value);
          if (font) {
            handleFontSelect(font, type);
            combobox.closeDropdown();
          }
        }}
      >
        <Combobox.Target>
          {selectedFont ? (
            renderSelectedFont(selectedFont, type)
          ) : (
            <Card
              shadow="sm"
              padding="md"
              radius="md"
              withBorder
              onClick={() => combobox.openDropdown()}
              style={{ cursor: 'pointer' }}
            >
              <Stack gap="xs">
                <Group gap="xs">
                  <ThemeIcon variant="light" size="sm" color="blue">
                    <IconTypography
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  </ThemeIcon>
                  <Text size="sm" fw={500}>
                    {type === 'header'
                      ? t('view.pdf.customFonts.headerFont')
                      : t('view.pdf.customFonts.textFont')}
                  </Text>
                </Group>
                <Text size="sm" c="dimmed">
                  {type === 'header'
                    ? t('view.pdf.customFonts.headerFontPlaceholder')
                    : t('view.pdf.customFonts.textFontPlaceholder')}
                </Text>
              </Stack>
            </Card>
          )}
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Header>
            <Input
              placeholder={t('view.pdf.searchFontsPlaceholder')}
              leftSection={
                <IconSearch style={{ width: rem(14), height: rem(14) }} />
              }
              value={searchValue}
              onChange={(event) => {
                const value = event.currentTarget.value;
                setSearchValue(value);
                combobox.updateSelectedOptionIndex();
                if (value.trim().length > 0) {
                  combobox.selectFirstOption();
                }
              }}
              onClick={(event) => {
                event.stopPropagation();
                combobox.openDropdown();
              }}
              onFocus={() => combobox.openDropdown()}
              size="xs"
              styles={{
                input: {
                  cursor: 'text',
                },
              }}
            />
          </Combobox.Header>
          <Combobox.Options>
            <ScrollArea.Autosize mah={300}>
              {filteredFonts.length > 0 ? (
                filteredFonts.map(renderFontOption)
              ) : (
                <Combobox.Empty>
                  {t('view.pdf.searchFontsNoResults')}
                </Combobox.Empty>
              )}
            </ScrollArea.Autosize>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    );
  };

  return (
    <Stack gap="md">
      {renderFontSelector('header')}
      {renderFontSelector('text')}

      <FontInfoDialog
        opened={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setSelectedPreview(null);
        }}
        headerFont={selectedPreview === 'header' ? headerFont : undefined}
        textFont={selectedPreview === 'text' ? textFont : undefined}
      />
    </Stack>
  );
}
