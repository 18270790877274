import {
  ActionIcon,
  Box,
  Card,
  Group,
  Input,
  ScrollArea,
  SegmentedControl,
  Stack,
  Text,
  ThemeIcon,
  rem,
} from '@mantine/core';
import {
  IconArrowDown,
  IconArrowUp,
  IconInfoCircle,
  IconSearch,
  IconTypography,
} from '@tabler/icons-react';
import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewportSize } from '@mantine/hooks';
import { motion, AnimatePresence } from 'framer-motion';
import type { FontCombination, FontMode } from '../../pdf/utils/font-types';
import { popularFontCombinations } from '../../pdf/utils/font-combinations';
import { fontMetadata } from '../../pdf/utils/font-metadata';
import { CustomFontCombination } from './CustomFontCombination';
import { FontInfoDialog } from './FontInfoDialog';
import { Font } from '../../pdf/utils/Font';

import '@fontsource-variable/dm-sans';
import '@fontsource-variable/inter';
import '@fontsource-variable/lora';
import '@fontsource-variable/montserrat';
import '@fontsource-variable/nunito';
import '@fontsource-variable/open-sans';
import '@fontsource-variable/oswald';
import '@fontsource-variable/outfit';
import '@fontsource-variable/playfair-display';
import '@fontsource-variable/quicksand';
import '@fontsource-variable/raleway';
import '@fontsource-variable/source-sans-3';
import '@fontsource-variable/spline-sans';
import '@fontsource-variable/work-sans';
import '@fontsource/dm-serif-display';
import '@fontsource/ibm-plex-sans';
import '@fontsource/ibm-plex-serif';
import '@fontsource/lato';
import '@fontsource/libre-baskerville';
import '@fontsource/merriweather';
import '@fontsource/poppins';
import '@fontsource/pt-sans';
import '@fontsource/roboto';
import '@fontsource/titillium-web';
import '@fontsource/ubuntu';

interface FontSelectorProps {
  onFontChange: (headerFont: string, textFont: string, index: number) => void;
  selectedCombinationIndex?: number;
  fontMode: FontMode;
  onFontModeChange: (mode: FontMode) => void;
  headerFont?: string;
  textFont?: string;
}

export default function FontSelector({
  onFontChange,
  selectedCombinationIndex,
  fontMode,
  onFontModeChange,
  headerFont,
  textFont,
}: FontSelectorProps) {
  const { t } = useTranslation();
  const { height } = useViewportSize();
  const viewport = useRef<HTMLDivElement>(null);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollDown, setCanScrollDown] = useState(true);

  const [selectedCombination, setSelectedCombination] = useState<number>(() => {
    if (fontMode === 'popular' && selectedCombinationIndex !== undefined) {
      const maxIndex = Math.max(0, popularFontCombinations.length - 1);
      return Math.max(0, Math.min(selectedCombinationIndex, maxIndex));
    }
    return -1;
  });

  useEffect(() => {
    if (fontMode === 'popular' && selectedCombinationIndex !== undefined) {
      setSelectedCombination(selectedCombinationIndex);
    }
  }, [fontMode, selectedCombinationIndex]);

  const [searchValue, setSearchValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSelect = (index: number) => {
    const maxIndex = Math.max(0, popularFontCombinations.length - 1);
    if (index < 0 || index > maxIndex) return;

    const combination = popularFontCombinations[index];
    if (!combination) return;

    // Only open dialog if clicking the already selected combination
    if (index === selectedCombination) {
      setIsDialogOpen(true);
    } else {
      setSelectedCombination(index);
      onFontChange(combination.headerFont, combination.textFont, index);
      onFontModeChange('popular');
    }
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSearchValue(event.currentTarget.value);
  };

  const filteredCombinations = popularFontCombinations.filter(
    (combo) =>
      combo.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      combo.description.en.toLowerCase().includes(searchValue.toLowerCase()) ||
      combo.category.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleScrollPositionChange = ({ y }: { y: number }) => {
    if (!viewport.current) return;

    const element = viewport.current;
    const maxScroll = element.scrollHeight - element.clientHeight;

    setCanScrollUp(y > 0);
    setCanScrollDown(y < maxScroll);
  };

  const scrollToIndex = (direction: 'up' | 'down') => {
    if (!viewport.current) return;

    const currentIndex = selectedCombination;
    const newIndex =
      direction === 'up'
        ? Math.max(0, currentIndex - 1)
        : Math.min(filteredCombinations.length - 1, currentIndex + 1);

    if (newIndex === currentIndex) return;

    handleSelect(newIndex);

    // Scroll to the new item
    const item = viewport.current.querySelector(`[data-index="${newIndex}"]`);
    if (item) {
      item.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const selectedFontCombination = popularFontCombinations[selectedCombination];
  const headerFontMeta = selectedFontCombination
    ? new Font(selectedFontCombination.headerFont)
    : headerFont
      ? new Font(headerFont)
      : null;
  const textFontMeta = selectedFontCombination
    ? new Font(selectedFontCombination.textFont)
    : textFont
      ? new Font(textFont)
      : null;

  const renderCombinationCard = (
    combination: FontCombination,
    index: number,
  ) => {
    const isSelected =
      combination === popularFontCombinations[selectedCombination];

    const headerFont = new Font(combination.headerFont);
    const textFont = new Font(combination.textFont);

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
        layout
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <Card
          shadow="sm"
          padding="xs"
          radius="md"
          withBorder
          onClick={() => handleSelect(index)}
          data-index={index}
          style={{
            cursor: 'pointer',
            backgroundColor: isSelected
              ? 'var(--mantine-color-blue-light)'
              : undefined,
          }}
        >
          <Stack gap={4}>
            <Group justify="space-between" align="center">
              <Text size="xs" c="dimmed" tt="uppercase">
                {combination.category}
              </Text>
              {isSelected && (
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: 'spring', stiffness: 500, damping: 25 }}
                >
                  <IconInfoCircle
                    style={{
                      width: rem(16),
                      height: rem(16),
                      color: 'var(--mantine-color-blue-filled)',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDialogOpen(true);
                    }}
                  />
                </motion.div>
              )}
            </Group>

            <Box>
              <motion.div layout transition={{ duration: 0.2 }}>
                <Text
                  size="lg"
                  style={{
                    fontFamily: combination.headerFontUI,
                    marginBottom: rem(4),
                    fontWeight: headerFont.getRecommendedHeadingWeight(),
                    letterSpacing: headerFont.recommendedLetterSpacing,
                    lineHeight: headerFont.recommendedLineHeight,
                  }}
                >
                  {combination.headerFont}
                </Text>
                <Text
                  size="sm"
                  style={{
                    fontFamily: combination.textFontUI,
                    fontWeight: textFont.getRecommendedBodyWeight(),
                    letterSpacing: textFont.recommendedLetterSpacing,
                    lineHeight: textFont.recommendedLineHeight,
                  }}
                >
                  {combination.textFont}
                </Text>
              </motion.div>
            </Box>
          </Stack>
        </Card>
      </motion.div>
    );
  };

  const handleCustomFontSelect = (
    headerFont: string,
    textFont: string,
  ): void => {
    onFontChange(headerFont, textFont, -1);
    onFontModeChange('custom');
    setSelectedCombination(-1);
  };

  return (
    <Stack gap="md">
      <Group gap="xs">
        <ThemeIcon variant="light" size="md">
          <IconTypography style={{ width: rem(16), height: rem(16) }} />
        </ThemeIcon>
        <Text fw={500} size="sm">
          {t('view.pdf.fontsTitle')}
        </Text>
      </Group>

      <motion.div
        initial={false}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        <SegmentedControl
          fullWidth
          value={fontMode}
          onChange={(value) => {
            onFontModeChange(value as FontMode);
          }}
          data={[
            {
              label: t('view.pdf.fonts.popular'),
              value: 'popular',
            },
            {
              label: t('view.pdf.fonts.custom'),
              value: 'custom',
            },
          ]}
        />
      </motion.div>

      {fontMode === 'popular' ? (
        <Box>
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Input
              placeholder={t('view.pdf.searchCombinationsPlaceholder')}
              value={searchValue}
              onChange={handleSearchChange}
              leftSection={
                <IconSearch style={{ width: rem(16), height: rem(16) }} />
              }
              mb="md"
            />
          </motion.div>

          <Group justify="center" mb="xs">
            <ActionIcon
              variant="subtle"
              size="md"
              radius="xl"
              disabled={!canScrollUp}
              onClick={() => scrollToIndex('up')}
              style={{ width: '100%', height: rem(24) }}
            >
              <IconArrowUp style={{ width: rem(16), height: rem(16) }} />
            </ActionIcon>
          </Group>

          <ScrollArea.Autosize
            mah={Math.min(450, height * 0.4)}
            viewportRef={viewport}
            onScrollPositionChange={handleScrollPositionChange}
          >
            <AnimatePresence>
              <Stack gap="md">
                {filteredCombinations.map((combination, index) => (
                  <div key={`${combination.name}-${index}`}>
                    {renderCombinationCard(combination, index)}
                  </div>
                ))}
              </Stack>
            </AnimatePresence>
          </ScrollArea.Autosize>

          <Group justify="center" mt="xs">
            <ActionIcon
              variant="subtle"
              size="md"
              radius="xl"
              disabled={!canScrollDown}
              onClick={() => scrollToIndex('down')}
              style={{ width: '100%', height: rem(24) }}
            >
              <IconArrowDown style={{ width: rem(16), height: rem(16) }} />
            </ActionIcon>
          </Group>

          <FontInfoDialog
            opened={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            headerFont={headerFontMeta}
            textFont={textFontMeta}
            headerPreviewText={selectedFontCombination?.sampleText.heading}
            textPreviewText={selectedFontCombination?.sampleText.body}
          />
        </Box>
      ) : (
        <CustomFontCombination
          onSelect={handleCustomFontSelect}
          initialHeaderFont={headerFont}
          initialTextFont={textFont}
        />
      )}
    </Stack>
  );
}
