import metadata from './raleway_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import raleway_variableUrlitalic100 from './ttf/raleway_variable/raleway_variable-italic-100.ttf?url';
import raleway_variableUrlitalic200 from './ttf/raleway_variable/raleway_variable-italic-200.ttf?url';
import raleway_variableUrlitalic300 from './ttf/raleway_variable/raleway_variable-italic-300.ttf?url';
import raleway_variableUrlitalic400 from './ttf/raleway_variable/raleway_variable-italic-400.ttf?url';
import raleway_variableUrlitalic500 from './ttf/raleway_variable/raleway_variable-italic-500.ttf?url';
import raleway_variableUrlitalic600 from './ttf/raleway_variable/raleway_variable-italic-600.ttf?url';
import raleway_variableUrlitalic700 from './ttf/raleway_variable/raleway_variable-italic-700.ttf?url';
import raleway_variableUrlitalic800 from './ttf/raleway_variable/raleway_variable-italic-800.ttf?url';
import raleway_variableUrlitalic900 from './ttf/raleway_variable/raleway_variable-italic-900.ttf?url';
import raleway_variableUrlnormal100 from './ttf/raleway_variable/raleway_variable-normal-100.ttf?url';
import raleway_variableUrlnormal200 from './ttf/raleway_variable/raleway_variable-normal-200.ttf?url';
import raleway_variableUrlnormal300 from './ttf/raleway_variable/raleway_variable-normal-300.ttf?url';
import raleway_variableUrlnormal400 from './ttf/raleway_variable/raleway_variable-normal-400.ttf?url';
import raleway_variableUrlnormal500 from './ttf/raleway_variable/raleway_variable-normal-500.ttf?url';
import raleway_variableUrlnormal600 from './ttf/raleway_variable/raleway_variable-normal-600.ttf?url';
import raleway_variableUrlnormal700 from './ttf/raleway_variable/raleway_variable-normal-700.ttf?url';
import raleway_variableUrlnormal800 from './ttf/raleway_variable/raleway_variable-normal-800.ttf?url';
import raleway_variableUrlnormal900 from './ttf/raleway_variable/raleway_variable-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Raleway',
  fonts: [
    {
      src: raleway_variableUrlitalic100,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: raleway_variableUrlitalic200,
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: raleway_variableUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: raleway_variableUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: raleway_variableUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: raleway_variableUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: raleway_variableUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: raleway_variableUrlitalic800,
      fontStyle: 'italic',
      fontWeight: 800,
    },
    {
      src: raleway_variableUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: raleway_variableUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: raleway_variableUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: raleway_variableUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: raleway_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: raleway_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: raleway_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: raleway_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: raleway_variableUrlnormal800,
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: raleway_variableUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
