import metadata from './source_sans_3_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import source_sans_3_variableUrlitalic200 from './ttf/source_sans_3_variable/source_sans_3_variable-italic-200.ttf?url';
import source_sans_3_variableUrlitalic300 from './ttf/source_sans_3_variable/source_sans_3_variable-italic-300.ttf?url';
import source_sans_3_variableUrlitalic400 from './ttf/source_sans_3_variable/source_sans_3_variable-italic-400.ttf?url';
import source_sans_3_variableUrlitalic500 from './ttf/source_sans_3_variable/source_sans_3_variable-italic-500.ttf?url';
import source_sans_3_variableUrlitalic600 from './ttf/source_sans_3_variable/source_sans_3_variable-italic-600.ttf?url';
import source_sans_3_variableUrlitalic700 from './ttf/source_sans_3_variable/source_sans_3_variable-italic-700.ttf?url';
import source_sans_3_variableUrlitalic800 from './ttf/source_sans_3_variable/source_sans_3_variable-italic-800.ttf?url';
import source_sans_3_variableUrlitalic900 from './ttf/source_sans_3_variable/source_sans_3_variable-italic-900.ttf?url';
import source_sans_3_variableUrlnormal200 from './ttf/source_sans_3_variable/source_sans_3_variable-normal-200.ttf?url';
import source_sans_3_variableUrlnormal300 from './ttf/source_sans_3_variable/source_sans_3_variable-normal-300.ttf?url';
import source_sans_3_variableUrlnormal400 from './ttf/source_sans_3_variable/source_sans_3_variable-normal-400.ttf?url';
import source_sans_3_variableUrlnormal500 from './ttf/source_sans_3_variable/source_sans_3_variable-normal-500.ttf?url';
import source_sans_3_variableUrlnormal600 from './ttf/source_sans_3_variable/source_sans_3_variable-normal-600.ttf?url';
import source_sans_3_variableUrlnormal700 from './ttf/source_sans_3_variable/source_sans_3_variable-normal-700.ttf?url';
import source_sans_3_variableUrlnormal800 from './ttf/source_sans_3_variable/source_sans_3_variable-normal-800.ttf?url';
import source_sans_3_variableUrlnormal900 from './ttf/source_sans_3_variable/source_sans_3_variable-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Source Sans 3',
  fonts: [
    {
      src: source_sans_3_variableUrlitalic200,
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: source_sans_3_variableUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: source_sans_3_variableUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: source_sans_3_variableUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: source_sans_3_variableUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: source_sans_3_variableUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: source_sans_3_variableUrlitalic800,
      fontStyle: 'italic',
      fontWeight: 800,
    },
    {
      src: source_sans_3_variableUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: source_sans_3_variableUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: source_sans_3_variableUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: source_sans_3_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: source_sans_3_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: source_sans_3_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: source_sans_3_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: source_sans_3_variableUrlnormal800,
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: source_sans_3_variableUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
