import React from 'react';
import { View } from '@react-pdf/renderer';
import { Section, SectionProps } from '../layout/Section';
import { Heading } from '../typography/Heading';
import { Text } from '../typography/Text';
import { BulletPoints } from '../typography/BulletPoints';

export interface Experience {
  company: string;
  position: string;
  startDate: string;
  endDate?: string;
  description: string[];
}

export interface ExperienceSectionProps extends SectionProps {
  title?: string;
  experiences: Experience[];
}

export const ExperienceSection: React.FC<ExperienceSectionProps> = ({
  theme,
  title = 'Work Experience',
  experiences,
  ...sectionProps
}) => {
  return (
    <Section theme={theme} {...sectionProps}>
      <Heading theme={theme} level={2}>
        {title}
      </Heading>
      {experiences.map((experience, index) => (
        <View
          key={index}
          style={{
            marginBottom: theme.spacing.md,
            marginTop: theme.spacing.md,
          }}
          wrap={false}
          minPresenceAhead={12}
        >
          <View wrap={false}>
            <Heading
              level={3}
              theme={theme}
              style={{ marginBottom: theme.spacing.xs, lineHeight: 1.2 }}
            >
              {experience.company}
            </Heading>
            <Text
              theme={theme}
              size="h3"
              style={{
                marginBottom: theme.spacing.xs,
              }}
              weight={500}
            >
              {experience.position}
            </Text>
            <Text
              theme={theme}
              size="small"
              style={{ marginBottom: theme.spacing.sm }}
            >
              {experience.startDate} - {experience.endDate}
            </Text>
          </View>
          <BulletPoints theme={theme} points={experience.description} />
        </View>
      ))}
    </Section>
  );
};
