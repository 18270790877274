import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { PDFProps } from '../../PDFProps';
import { BaseTemplate } from '../../core/BaseTemplate';
import { Section } from '../../components/layout/Section';
import { Heading } from '../../components/typography/Heading';
import { Text } from '../../components/typography/Text';
import { defaultTheme } from '../../themes/presets/default';
import { PDFTheme } from '../../themes/types';
import { usePDFTranslation } from '../../utils/translations';
import { Font } from '../../utils/Font';
import { formatPDFDate } from '../../utils/dates';
import { BulletPoints } from '../../ResumePDF/BulletPoints';

export const ModernCoverLetterTemplate: React.FC<PDFProps> = ({
  resume,
  user,
  language,
  title,
  primaryColor,
  headerFont = defaultTheme.typography.headerFont.name,
  textFont = defaultTheme.typography.textFont.name,
}) => {
  const { t } = usePDFTranslation(language);

  const currentDate = formatPDFDate(new Date().toISOString(), language);

  const theme: PDFTheme = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      primary: primaryColor || defaultTheme.colors.primary,
    },
    typography: {
      ...defaultTheme.typography,
      headerFont: new Font(headerFont),
      textFont: new Font(textFont),
      sizes: {
        ...defaultTheme.typography.sizes,
        h1: 24,
        h2: 16,
        h3: 14,
        body: 10,
        small: 9,
      },
    },
    spacing: {
      ...defaultTheme.spacing,
      xs: 3,
      sm: 6,
      md: 12,
      lg: 18,
      xl: 24,
    },
  };

  const styles = StyleSheet.create({
    header: {
      marginBottom: theme.spacing.md,
      textAlign: 'center',
      paddingHorizontal: theme.spacing.lg,
    },
    date: {
      fontSize: theme.typography.sizes.body,
      color: theme.colors.muted,
      marginBottom: theme.spacing.md,
      textAlign: 'right',
    },
    intro: {
      fontSize: theme.typography.sizes.body,
      marginTop: theme.spacing.xs,
      color: theme.colors.muted,
      maxWidth: '80%',
      alignSelf: 'center',
    },
    contactInfo: {
      fontSize: theme.typography.sizes.body,
      marginBottom: theme.spacing.xs,
    },
    content: {
      padding: theme.spacing.md,
    },
    paragraph: {
      fontSize: theme.typography.sizes.body,
      marginBottom: theme.spacing.md,
      lineHeight: 1.5,
    },
    bulletList: {
      marginBottom: theme.spacing.md,
    },
    bulletPointContainer: {
      flexDirection: 'row',
      marginBottom: theme.spacing.xs,
    },
    bulletPointSymbol: {
      width: 10,
      fontSize: theme.typography.sizes.body,
      color: theme.colors.text,
    },
    bulletPointText: {
      flex: 1,
      fontSize: theme.typography.sizes.body,
      color: theme.colors.text,
      lineHeight: 1.5,
    },
  });

  const paragraphs = resume.coverLetter?.split('\n\n') || [];

  return (
    <BaseTemplate user={user} language={language} title={title} theme={theme}>
      <Section theme={theme} spacing="md" background={false}>
        <View style={styles.header}>
          <Heading theme={theme} level={1}>
            {user.profile?.firstName} {user.profile?.lastName}
          </Heading>
          <Text theme={theme} style={styles.intro}>
            {resume?.intro || t('resume.personalInformation')}
          </Text>
        </View>
      </Section>

      <Section theme={theme} spacing="md" background={false}>
        <Text theme={theme} style={styles.date}>
          {currentDate}
        </Text>

        <View style={styles.content}>
          {paragraphs.map((paragraph, index) => {
            // Check if the paragraph contains bullet points (starts with •)
            if (paragraph.includes('• ')) {
              const points = paragraph
                .split('• ')
                .filter((point) => point.trim().length > 0);
              return (
                <BulletPoints key={index} points={points} styles={styles} />
              );
            }
            return (
              <Text key={index} theme={theme} style={styles.paragraph}>
                {paragraph}
              </Text>
            );
          })}
        </View>
      </Section>
    </BaseTemplate>
  );
};
