import metadata from './libre_baskerville.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import libre_baskervilleUrlitalic400 from './ttf/libre_baskerville/libre_baskerville-italic-400.ttf?url';
import libre_baskervilleUrlnormal400 from './ttf/libre_baskerville/libre_baskerville-normal-400.ttf?url';
import libre_baskervilleUrlnormal700 from './ttf/libre_baskerville/libre_baskerville-normal-700.ttf?url';

const fontConfig: FontConfig = {
  family: 'Libre Baskerville',
  fonts: [
    {
      src: libre_baskervilleUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: libre_baskervilleUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: libre_baskervilleUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
