import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Group,
  Paper,
  Select,
  Stack,
  Text,
  Title,
  useMantineTheme,
  Menu,
} from '@mantine/core';
import { useMediaQuery, useToggle } from '@mantine/hooks';
import {
  Document,
  Font as PDFFont,
  Page,
  Text as PDFText,
  usePDF,
} from '@react-pdf/renderer';
import {
  IconAdjustments,
  IconDownload,
  IconRotate2,
  IconFileCv,
  IconMailSpark,
  IconChevronDown,
} from '@tabler/icons-react';
import type { HyphenationFunctionSync } from 'hyphen';
import { hyphenateSync as hyphenateDe } from 'hyphen/de-1996';
import { hyphenateSync as hyphenateEn } from 'hyphen/en-us';
import { hyphenateSync as hyphenateEs } from 'hyphen/es';
import { hyphenateSync as hyphenateFr } from 'hyphen/fr';
import { hyphenateSync as hyphenateIt } from 'hyphen/it';
import { hyphenateSync as hyphenatePt } from 'hyphen/pt';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { IResume, IResumeJSON } from '../../models/Resume';
import { IUser, SubscriptionType } from '../../models/User';

import { coverLetterTemplateBasic } from '../../pdf/CoverLetterBasicPDF/CoverLetterBasicPDF';
import { coverLetterTemplateCleanColumn } from '../../pdf/CoverLetterCleanColumnPDF/CoverLetterCleanColumnPDF';
import { coverLetterTemplateGraphic } from '../../pdf/CoverLetterGraphicPDF/CoverLetterGraphicPDF';
import { coverLetterTemplateSideBar } from '../../pdf/CoverLetterSideBarPDF/CoverLetterSideBarPDF';
import { coverLetterTemplateStylish } from '../../pdf/CoverLetterStylishPDF/CoverLetterStylishPDF';
import { PDFProps } from '../../pdf/PDFProps';
import { resumeTemplateBasic } from '../../pdf/ResumeBasicPDF/ResumeBasicPDF';
import { resumeTemplateCleanColumn } from '../../pdf/ResumeCleanColumnPDF/ResumeCleanColumnPDF';
import { resumeTemplateGraphic } from '../../pdf/ResumeGraphicPDF/ResumeGraphicPDF';
import { resumeTemplateSideBar } from '../../pdf/ResumeSideBarPDF/ResumeSideBarPDF';
import {
  DEFAULT_HEADER_FONT,
  DEFAULT_TEXT_FONT,
  resumeTemplateStylish,
} from '../../pdf/ResumeStylishPDF/ResumeStylishPDF';
import {
  coverLetterTemplateModern,
  resumeTemplateModern,
} from '../../pdf/templates/modern';
import { getLogger } from '../../services/Logger';
import FontSelector from './FontSelector';
import { ResumePDFViewer } from './ResumePDFViewer';
import { TemplateModal } from './TemplateModal';
import { TemplateSelector } from './TemplateSelector';
import { ColorPicker } from './ColorPicker';
import { registerPDFFonts } from '../../pdf/utils/fontRegistration';
import { popularFontCombinations } from '../../pdf/utils/font-combinations';
import type { FontMode } from '../../pdf/utils/font-types';
import {
  useDocumentStyles,
  useUpdateDocumentStyles,
  DocumentStyle,
} from '../../queries/DocumentStyleQueries';
import { useDebouncedCallback } from '@mantine/hooks';
import { Font } from '../../pdf/utils/Font';

const logger = getLogger('ResumeDocumentView');

export interface TemplateEntry {
  label: string;
  component: React.FC<PDFProps>;
  preview?: string;
  previewFallback: string;
}

export type DocumentType = 'resume' | 'coverLetter';

const resumeTemplates: TemplateEntry[] = [
  resumeTemplateBasic,
  resumeTemplateSideBar,
  resumeTemplateGraphic,
  resumeTemplateCleanColumn,
  resumeTemplateStylish,
  resumeTemplateModern,
];

const coverLetterTemplates: TemplateEntry[] = [
  coverLetterTemplateBasic,
  coverLetterTemplateSideBar,
  coverLetterTemplateGraphic,
  coverLetterTemplateCleanColumn,
  coverLetterTemplateStylish,
  coverLetterTemplateModern,
];

const pageSizes = [
  { label: 'A4', value: 'A4' },
  { label: 'Letter', value: 'Letter' },
];

const hyphenators = new Map<string, HyphenationFunctionSync>();
hyphenators.set('en', hyphenateEn);
hyphenators.set('de', hyphenateDe);
hyphenators.set('es', hyphenateEs);
hyphenators.set('fr', hyphenateFr);
hyphenators.set('it', hyphenateIt);
hyphenators.set('pt', hyphenatePt);

export const ResumeDocumentView: React.FC<{
  resumeId: IResume['id'];
  resume: IResumeJSON;
  user: IUser;
  language: string;
  title?: string;
  coverLetter?: string;
}> = ({ resumeId, resume, user, language, title }) => {
  const { t } = useTranslation();
  const [instance, updateInstance] = usePDF();
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateEntry>(
    resumeTemplates[0],
  );
  const [selectedCoverLetterTemplate, setSelectedCoverLetterTemplate] =
    useState<TemplateEntry>(coverLetterTemplates[0]);
  const [primaryColor, setPrimaryColor] = useState<string | undefined>(
    '#2F7D7A',
  );
  const [pageSize, setPageSize] = useState(pageSizes[0].value);
  const [headerFont, setHeaderFont] = useState<Font>(
    new Font(DEFAULT_HEADER_FONT),
  );
  const [textFont, setTextFont] = useState<Font>(new Font(DEFAULT_TEXT_FONT));
  const [fontMode, setFontMode] = useState<FontMode>('popular');
  const [selectedFontCombination, setSelectedFontCombination] =
    useState<number>(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [documentType, toggleDocumentType] = useToggle<DocumentType>([
    'resume',
    'coverLetter',
  ]);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const enableHyphenation = false;

  const { data: documentStyles } = useDocumentStyles(resumeId);
  const updateDocumentStyles = useUpdateDocumentStyles(resumeId);

  const debouncedUpdateStyles = useDebouncedCallback(
    (styles: DocumentStyle) => {
      updateDocumentStyles.mutate(styles);
    },
    1000,
  );

  useEffect(() => {
    if (enableHyphenation) {
      logger.debug(`Setting hyphenator for language ${language}`);
      const hyphenate = hyphenators.get(language);
      // TODO: Workaround for broken @react-pdf/font types
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      (PDFFont as any).registerHyphenationCallback(
        (word: string) => hyphenate?.(word).split('\u00AD') ?? [word],
      );
    } else {
      logger.debug('Disabling hyphenation');
      // TODO: Workaround for broken @react-pdf/font types
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      (PDFFont as any).registerHyphenationCallback((word: string) => [word]);
    }
  }, [language]);

  useEffect(() => {
    if (documentStyles) {
      const template = resumeTemplates.find(
        (t) => t.label === documentStyles.templateName,
      );
      const coverLetterTemplate = coverLetterTemplates.find(
        (t) => t.label === documentStyles.coverLetterTemplateName,
      );

      if (template) setSelectedTemplate(template);
      if (coverLetterTemplate)
        setSelectedCoverLetterTemplate(coverLetterTemplate);
      if (documentStyles.primaryColor)
        setPrimaryColor(documentStyles.primaryColor);
      if (documentStyles.pageSize) setPageSize(documentStyles.pageSize);

      // Set fonts and try to find matching combination
      setHeaderFont(new Font(documentStyles.headerFont));
      setTextFont(new Font(documentStyles.textFont));
      setFontMode(documentStyles.fontMode);

      if (documentStyles.fontMode === 'popular') {
        // Try to find matching combination
        const combinationIndex = popularFontCombinations.findIndex(
          (combo) =>
            combo.headerFont === documentStyles.headerFont &&
            combo.textFont === documentStyles.textFont,
        );
        setSelectedFontCombination(Math.max(0, combinationIndex));
      }
    }
  }, [documentStyles]);

  useEffect(() => {
    if (!documentStyles) return;

    const styles: DocumentStyle = {
      templateName: selectedTemplate.label,
      coverLetterTemplateName: selectedCoverLetterTemplate.label,
      primaryColor: primaryColor || '#2F7D7A',
      pageSize,
      headerFont: headerFont.name,
      textFont: textFont.name,
      fontMode,
    };

    debouncedUpdateStyles(styles);
  }, [
    selectedTemplate,
    selectedCoverLetterTemplate,
    primaryColor,
    pageSize,
    headerFont,
    textFont,
    fontMode,
  ]);

  const handleFontChange = (
    newHeaderFont: string,
    newTextFont: string,
    combinationIndex: number,
  ) => {
    setHeaderFont(new Font(newHeaderFont));
    setTextFont(new Font(newTextFont));
    setSelectedFontCombination(combinationIndex);
    void registerPDFFonts(newHeaderFont, newTextFont);
  };

  const handleReset = () => {
    setSelectedTemplate(resumeTemplates[0]);
    setSelectedCoverLetterTemplate(coverLetterTemplates[0]);
    setPrimaryColor('#2F7D7A');
    setPageSize(pageSizes[0].value);
    setHeaderFont(new Font(DEFAULT_HEADER_FONT));
    setTextFont(new Font(DEFAULT_TEXT_FONT));
    setFontMode('popular');
    setSelectedFontCombination(0);
  };

  const handleTemplateSelect = (template: TemplateEntry) => {
    if (documentType === 'coverLetter') {
      setSelectedCoverLetterTemplate(template);
    } else {
      setSelectedTemplate(template);
    }
    setModalOpened(false);
  };

  const templates =
    documentType === 'resume' ? resumeTemplates : coverLetterTemplates;

  const downloadFileName =
    (t(`download.${documentType}FileTitle`) as string) +
    (title ? ` - ${title}` : '') +
    '.pdf';

  const currentTemplate =
    documentType === 'resume' ? selectedTemplate : selectedCoverLetterTemplate;

  const documentTypeOptions = [
    {
      label: t('view.resumeTab'),
      value: 'resume',
      icon: IconFileCv,
    },
    {
      label: t('view.coverLetterTab'),
      value: 'coverLetter',
      icon: IconMailSpark,
    },
  ];

  const currentDocumentType = documentTypeOptions.find(
    (option) => option.value === documentType,
  );

  useEffect(() => {
    const loadFonts = async () => {
      try {
        await registerPDFFonts(headerFont.name, textFont.name);
        logger.debug(
          `Fonts registered successfully: Header '${headerFont.name}', Text '${textFont.name}'`,
        );

        const templateDocument = (
          <Suspense
            fallback={
              <Document>
                <Page>
                  <PDFText>{t('view.pdf.rendering')}</PDFText>
                </Page>
              </Document>
            }
          >
            <currentTemplate.component
              resume={resume}
              user={user}
              resumeId={resumeId}
              language={language}
              title={title}
              primaryColor={primaryColor}
              pageSize={pageSize}
              headerFont={headerFont.name}
              textFont={textFont.name}
            />
          </Suspense>
        );
        updateInstance(templateDocument);
      } catch (error) {
        logger.error('Error loading fonts:', error);
      }
    };

    void loadFonts();
  }, [
    currentTemplate,
    resume,
    user,
    resumeId,
    language,
    title,
    primaryColor,
    pageSize,
    headerFont,
    textFont,
  ]);

  return (
    <Container fluid p={0}>
      <Paper shadow="xs" p="md" mb="md" radius={0}>
        <Group justify="space-between" align="center">
          <Box>
            <Title order={2}>{t('view.pdf.title')}</Title>
            {user.subscriptionType === SubscriptionType.FREE && (
              <Anchor component={Link} c="dimmed" to={AppRouteURL.subscribe}>
                {t('view.pdf.upgrade')}
              </Anchor>
            )}
          </Box>
          {isMobile && (
            <ActionIcon
              variant="light"
              size="lg"
              onClick={() => {
                setSettingsOpened(true);
              }}
            >
              <IconAdjustments />
            </ActionIcon>
          )}
        </Group>
      </Paper>

      <Grid gutter={isMobile ? 'xs' : 'xl'}>
        {!isMobile && (
          <Grid.Col span={{ base: 12, sm: 12, md: 4, lg: 3 }}>
            <Paper shadow="xs" p="md" withBorder>
              <Stack gap="lg">
                <Box>
                  <Text fw={500} size="sm" c="dimmed" mb="xs">
                    {t('view.pdf.documentType')}
                  </Text>
                  <Menu shadow="md" width={200}>
                    <Menu.Target>
                      <Button
                        variant="light"
                        fullWidth
                        rightSection={<IconChevronDown size={16} />}
                        leftSection={
                          currentDocumentType && (
                            <currentDocumentType.icon size={16} />
                          )
                        }
                      >
                        {currentDocumentType?.label}
                      </Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      {documentTypeOptions.map((option) => (
                        <Menu.Item
                          key={option.value}
                          leftSection={<option.icon size={16} />}
                          onClick={() => toggleDocumentType()}
                          fw={option.value === documentType ? 500 : 400}
                        >
                          {option.label}
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                </Box>

                <TemplateSelector
                  currentTemplate={currentTemplate}
                  onOpenModal={() => {
                    setModalOpened(true);
                  }}
                />

                <FontSelector
                  onFontChange={handleFontChange}
                  selectedCombinationIndex={selectedFontCombination}
                  fontMode={fontMode}
                  onFontModeChange={setFontMode}
                  headerFont={headerFont.name}
                  textFont={textFont.name}
                />

                <Select
                  label={
                    <Text fw={500} size="sm" c="dimmed">
                      {t('view.pdf.pageSizeLabel')}
                    </Text>
                  }
                  data={pageSizes}
                  value={pageSize}
                  onChange={(value) => {
                    setPageSize(value || pageSizes[0].value);
                  }}
                />

                <ColorPicker
                  label={t('view.pdf.primaryColorLabel')}
                  value={primaryColor}
                  onChange={setPrimaryColor}
                />

                <Button
                  leftSection={<IconRotate2 />}
                  onClick={() => {
                    handleReset();
                  }}
                  variant="light"
                  fullWidth
                >
                  {t('view.pdf.resetButtonTitle')}
                </Button>

                <Button
                  loading={instance.loading}
                  component="a"
                  href={instance.url || undefined}
                  download={downloadFileName}
                  leftSection={<IconDownload />}
                  fullWidth
                >
                  {t('view.pdf.downloadButtonTitle')}
                </Button>
              </Stack>
            </Paper>
          </Grid.Col>
        )}

        <Grid.Col
          span={{
            base: 12,
            sm: 12,
            md: isMobile ? 12 : 8,
            lg: isMobile ? 12 : 9,
          }}
        >
          <Paper shadow="xs" style={{ height: '100%' }}>
            <Box
              p={isMobile ? 0 : 'sm'}
              style={{
                height: '100%',
                minHeight: isMobile ? 500 : 690,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ResumePDFViewer
                width="100%"
                height="100%"
                showToolbar={true}
                blob={instance.blob}
                isGenerating={instance.loading}
              />
            </Box>
          </Paper>
        </Grid.Col>
      </Grid>

      <TemplateModal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
        }}
        templates={templates}
        onTemplateSelect={handleTemplateSelect}
        isMobile={!!isMobile}
      />

      <Drawer
        opened={settingsOpened}
        onClose={() => {
          setSettingsOpened(false);
        }}
        title={t('view.pdf.settings')}
        position="right"
        size="100%"
        styles={{
          body: {
            padding: theme.spacing.md,
          },
          header: {
            padding: theme.spacing.md,
          },
        }}
      >
        <Stack gap="md">
          <Box>
            <Text fw={500} size="sm" c="dimmed" mb="xs">
              {t('view.pdf.documentType')}
            </Text>
            <Menu shadow="md" width="100%">
              <Menu.Target>
                <Button
                  variant="light"
                  fullWidth
                  rightSection={<IconChevronDown size={16} />}
                  leftSection={
                    currentDocumentType && (
                      <currentDocumentType.icon size={16} />
                    )
                  }
                >
                  {currentDocumentType?.label}
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                {documentTypeOptions.map((option) => (
                  <Menu.Item
                    key={option.value}
                    leftSection={<option.icon size={16} />}
                    onClick={() => toggleDocumentType()}
                    fw={option.value === documentType ? 500 : 400}
                  >
                    {option.label}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          </Box>

          <TemplateSelector
            currentTemplate={currentTemplate}
            onOpenModal={() => {
              setModalOpened(true);
            }}
          />

          <FontSelector
            onFontChange={handleFontChange}
            selectedCombinationIndex={selectedFontCombination}
            fontMode={fontMode}
            onFontModeChange={setFontMode}
            headerFont={headerFont.name}
            textFont={textFont.name}
          />

          <Select
            label={
              <Text fw={500} size="sm" c="dimmed">
                {t('view.pdf.pageSizeLabel')}
              </Text>
            }
            data={pageSizes}
            value={pageSize}
            onChange={(value) => {
              setPageSize(value || pageSizes[0].value);
            }}
          />

          <ColorPicker
            label={t('view.pdf.primaryColorLabel')}
            value={primaryColor}
            onChange={setPrimaryColor}
          />

          <Button
            leftSection={<IconRotate2 />}
            onClick={() => {
              handleReset();
            }}
            variant="light"
            fullWidth
          >
            {t('view.pdf.resetButtonTitle')}
          </Button>

          <Button
            loading={instance.loading}
            component="a"
            href={instance.url || undefined}
            download={downloadFileName}
            leftSection={<IconDownload />}
            fullWidth
          >
            {isMobile
              ? t('view.pdf.downloadButtonTitleShort')
              : t('view.pdf.downloadButtonTitle')}
          </Button>
        </Stack>
      </Drawer>
    </Container>
  );
};
