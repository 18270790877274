import {
  ActionIcon,
  Box,
  Container,
  Group,
  Paper,
  SegmentedControl,
  Stack,
  Tabs,
  Text,
  Title,
  ScrollArea,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconPencil,
  IconChevronLeft,
  IconChevronRight,
} from '@tabler/icons-react';
import React, { lazy, useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { CoverLetterView } from '../../components/CoverLetterView/CoverLetterView';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { ResumeDocumentView } from '../../components/ResumeDocumentView/ResumeDocumentView';
import { ResumeHistoryView } from '../../components/ResumeHistoryView/ResumeHistoryView';
import { ResumeInfoView } from '../../components/ResumeInfoView/ResumeInfoView';
import { ResumeView } from '../../components/ResumeView/ResumeView';
import { useProfileQuery } from '../../models/ProfileQueries';
import { IResumeWithSuggestion, ResumeStatus } from '../../models/Resume';
import { SubscriptionType, useUserQuery } from '../../models/User';
import {
  useResumeQuery,
  useResumeCountQuery,
} from '../../queries/ResumeQueries';
import { features } from '../../services/Features';
import { getLogger } from '../../services/Logger';
import { TitleModal } from './TitleModal';
import { NPSSurvey, NPS_TOUR_KEY } from '../../components/NPSSurvey/NPSSurvey';

const logger = getLogger('ViewResumePage');

const InterviewTab = lazy(
  () =>
    import(
      /* webpackChunkName: "view-resume" */ '../../components/InterviewTab/InterviewTab'
    ),
);

enum TABS {
  RESUME = 'resume',
  LETTER = 'letter',
  INFO = 'info',
  HISTORY = 'history',
  PDF = 'pdf',
  INTERVIEW = 'interview',
}

const SuggestionNotFound = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title order={2}>{t('view.pageTitle')}</Title>
      <Text>{t('view.noResumeFound')}</Text>
    </Container>
  );
};

export const ViewResumePage: React.FC = () => {
  const { resumeId, tab } = useParams();
  const theme = useMantineTheme();
  const largeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
  const navigate = useNavigate();
  const [titleModalOpen, setTitleModalOpen] = useState(false);
  const [npsOpened, setNpsOpened] = useState(false);

  const { data: user, isPending: userLoading } = useUserQuery();
  const { data: profile, isPending: profileLoading } = useProfileQuery();
  const { data: resumeData, isPending: resumeLoading } =
    useResumeQuery(resumeId);
  const { data: resumeCount } = useResumeCountQuery();

  useEffect(() => {
    if (
      resumeCount &&
      resumeCount?.count >= 2 &&
      user &&
      !user.toursDone?.includes(NPS_TOUR_KEY) &&
      resumeData?.status === ResumeStatus.Ok
    ) {
      setNpsOpened(true);
    }
  }, [resumeCount?.count, user, resumeData?.status]);

  const { t, i18n } = useTranslation();

  const menuData = useMemo(() => {
    const entries = [
      { value: TABS.RESUME, label: t('view.resumeTab') },
      { value: TABS.LETTER, label: t('view.coverLetterTab') },
      { value: TABS.INFO, label: t('view.infoTab') },
      { value: TABS.HISTORY, label: t('view.historyTab') },
      { value: TABS.PDF, label: t('view.pdfTab') },
    ];

    if (features.enabled('interview')) {
      entries.push({ value: TABS.INTERVIEW, label: t('view.interviewTab') });
    }
    return entries;
  }, [i18n.language]);

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  // Effect to scroll to selected pill
  useEffect(() => {
    if (!largeScreen && scrollAreaRef.current) {
      const selectedPill = scrollAreaRef.current.querySelector(
        '[data-active="true"]',
      );
      if (selectedPill) {
        setTimeout(() => {
          selectedPill.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }, 0);
      }
    }
  }, [tab, largeScreen]);

  if (
    (!user && userLoading) ||
    (!profile && profileLoading) ||
    (!resumeData && resumeLoading)
  ) {
    return <LoadingScreen />;
  }

  if (!resumeData || !user || !profile) {
    return <SuggestionNotFound />;
  }

  if (!resumeData.suggestion) {
    // For error states, show preview step with error message
    if (
      resumeData.status === ResumeStatus.InvalidURL ||
      resumeData.status === ResumeStatus.Error
    ) {
      return (
        <Navigate
          to={`${AppRouteURL.resumes.create}?step=preview&resumeId=${resumeId}`}
          replace={true}
        />
      );
    }
    // For generating state, show generation step
    if (resumeData.status === ResumeStatus.Generating) {
      return (
        <Navigate
          to={`${AppRouteURL.resumes.create}?step=generation&resumeId=${resumeId}`}
          replace={true}
        />
      );
    }
    // For preparing state, show preview step with loading state
    if (resumeData.status === ResumeStatus.Preparing) {
      return (
        <Navigate
          to={`${AppRouteURL.resumes.create}?step=preview&resumeId=${resumeId}`}
          replace={true}
        />
      );
    }
    // For all other states (New), go to input step
    return (
      <Navigate
        to={`${AppRouteURL.resumes.create}?resumeId=${resumeId}`}
        replace={true}
      />
    );
  }

  const resume = resumeData as IResumeWithSuggestion;

  const hidden =
    !user ||
    (!resume.isPaid && user.subscriptionType === SubscriptionType.FREE);

  user.profile = profile;

  const currentMenuEntry =
    menuData.find((entry) => entry.value === tab) || menuData[0];
  const currentMenuIndex =
    currentMenuEntry && menuData.indexOf(currentMenuEntry);
  const nextMenuEntry =
    currentMenuIndex === menuData.length - 1
      ? menuData[0]
      : menuData[currentMenuIndex + 1];
  const previousMenuEntry =
    currentMenuIndex === 0
      ? menuData[menuData.length - 1]
      : menuData[currentMenuIndex - 1];

  return (
    <Container size="xl" p={0}>
      {user && (
        <NPSSurvey
          opened={npsOpened}
          onClose={() => {
            setNpsOpened(false);
          }}
        />
      )}
      <Bowl title={t('view.pageTitle')} />

      <Paper shadow="sm" radius="md" p={largeScreen ? 'xl' : 'md'} mb="xl">
        <Stack gap="lg">
          {/* Header section with title and navigation */}
          <Stack gap="md">
            {/* Title and edit button */}
            <Group justify="space-between" align="center" wrap="nowrap">
              <Group gap="xs">
                <Title
                  order={2}
                  style={{
                    fontSize: largeScreen ? '2rem' : '1.5rem',
                    fontWeight: 600,
                  }}
                >
                  {resume.title || t('view.pageTitle')}
                </Title>
                <ActionIcon
                  variant="light"
                  size="md"
                  color="blue"
                  onClick={() => {
                    setTitleModalOpen(true);
                  }}
                  title={t('view.editTitle')}
                >
                  <IconPencil size="1rem" />
                </ActionIcon>
              </Group>
            </Group>

            {/* Navigation section */}
            <Box>
              <Group
                justify="center"
                gap={largeScreen ? 'xl' : 'xs'}
                wrap="nowrap"
                style={{
                  minHeight: 36,
                }}
              >
                <ActionIcon
                  variant="light"
                  size={largeScreen ? 'lg' : 'md'}
                  onClick={() => {
                    void navigate(
                      `${AppRouteURL.resumes.view}/${resumeId}/${previousMenuEntry.value}`,
                    );
                  }}
                >
                  <IconChevronLeft size={largeScreen ? '1.2rem' : '1rem'} />
                </ActionIcon>
                <ScrollArea.Autosize
                  ref={scrollAreaRef}
                  mah={50}
                  type="never"
                  offsetScrollbars
                  style={{
                    maxWidth: largeScreen ? 'unset' : 'calc(100vw - 140px)',
                  }}
                >
                  <SegmentedControl
                    size="sm"
                    radius="xl"
                    data={menuData}
                    value={tab || TABS.RESUME}
                    onChange={(value) => {
                      void navigate(
                        `${AppRouteURL.resumes.view}/${resumeId}/${value}`,
                      );
                    }}
                    styles={(theme) => ({
                      root: {
                        backgroundColor: theme.colors.gray[0],
                        '@media (maxWidth: 600px)': {
                          minWidth: 'max-content',
                        },
                      },
                      control: {
                        minWidth: largeScreen ? '100px' : '80px',
                        '@media (maxWidth: 600px)': {
                          minWidth: '70px',
                          padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
                        },
                      },
                      label: {
                        fontSize: largeScreen
                          ? theme.fontSizes.sm
                          : theme.fontSizes.xs,
                        padding: largeScreen
                          ? `${theme.spacing.xs} ${theme.spacing.md}`
                          : `${theme.spacing.xs} ${theme.spacing.sm}`,
                        whiteSpace: 'nowrap',
                        '@media (maxWidth: 600px)': {
                          padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
                        },
                      },
                      indicator: {
                        '@media (maxWidth: 600px)': {
                          transform: 'scale(0.95)',
                        },
                      },
                    })}
                  />
                </ScrollArea.Autosize>
                <ActionIcon
                  variant="light"
                  size={largeScreen ? 'lg' : 'md'}
                  onClick={() => {
                    void navigate(
                      `${AppRouteURL.resumes.view}/${resumeId}/${nextMenuEntry.value}`,
                    );
                  }}
                >
                  <IconChevronRight size={largeScreen ? '1.2rem' : '1rem'} />
                </ActionIcon>
              </Group>
            </Box>
          </Stack>

          {/* Content section */}
          <Box mt="md">
            <Tabs value={tab || TABS.RESUME} keepMounted={false}>
              <Tabs.Panel value={TABS.RESUME}>
                <ResumeView resume={resume} user={user} hidden={hidden} />
              </Tabs.Panel>
              <Tabs.Panel value={TABS.LETTER}>
                <CoverLetterView resume={resume} hidden={hidden} />
              </Tabs.Panel>
              <Tabs.Panel value={TABS.INFO}>
                <ResumeInfoView resume={resume} hidden={hidden} />
              </Tabs.Panel>
              <Tabs.Panel value={TABS.HISTORY}>
                <ResumeHistoryView resume={resume} />
              </Tabs.Panel>
              <Tabs.Panel value={TABS.PDF}>
                <ResumeDocumentView
                  resume={resume.suggestion}
                  user={user}
                  language={resume.language as string}
                  resumeId={resume.id}
                  title={resume.title}
                />
              </Tabs.Panel>
              {features.enabled('interview') && (
                <Tabs.Panel value={TABS.INTERVIEW}>
                  <React.Suspense fallback={<LoadingScreen />}>
                    <InterviewTab resume={resume} />
                  </React.Suspense>
                </Tabs.Panel>
              )}
            </Tabs>
          </Box>
        </Stack>
      </Paper>

      <TitleModal
        opened={titleModalOpen}
        onClose={() => {
          setTitleModalOpen(false);
        }}
        resume={resume}
      />
    </Container>
  );
};

export default ViewResumePage;
