import React, { PropsWithChildren } from 'react';
import { View } from '@react-pdf/renderer';
import { PDFTheme } from '../../themes/types';
import { Style } from '@react-pdf/types';

export interface GridProps extends PropsWithChildren {
  theme: PDFTheme;
  columns?: number;
  gap?: keyof PDFTheme['spacing'];
  style?: Style;
  columnWidths?: string[];
  fixed?: boolean;
}

export const Grid: React.FC<GridProps> = ({
  children,
  theme,
  columns = 2,
  gap = 'md',
  style = {},
  columnWidths,
  fixed = false,
}) => {
  const baseStyle: Style = {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing[gap],
    ...style,
  };

  const getChildWidth = (index: number): string => {
    if (columnWidths?.[index]) {
      return columnWidths[index];
    }
    return `${100 / columns}%`;
  };

  const childrenArray = React.Children.toArray(children);

  return (
    <View style={baseStyle}>
      {childrenArray.map((child, index) => (
        <View
          key={index}
          fixed={fixed}
          style={{
            width: getChildWidth(index),
            paddingRight:
              index < childrenArray.length - 1 ? theme.spacing[gap] : 0,
          }}
        >
          {child}
        </View>
      ))}
    </View>
  );
};
