import { TemplateEntry } from '../../../components/ResumeDocumentView/ResumeDocumentView';
import { ModernTemplate } from './ModernTemplate';
import { ModernCoverLetterTemplate } from './ModernCoverLetterTemplate';

// Import preview images
import previewPng from './preview-modern.png';
import previewWebP from './preview-modern.webp';

export const resumeTemplateModern: TemplateEntry = {
  label: 'Modern',
  component: ModernTemplate,
  preview: previewWebP,
  previewFallback: previewPng,
};

export const coverLetterTemplateModern: TemplateEntry = {
  label: 'Modern',
  component: ModernCoverLetterTemplate,
  preview: previewWebP,
  previewFallback: previewPng,
};
