import metadata from './lora_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import lora_variableUrlitalic400 from './ttf/lora_variable/lora_variable-italic-400.ttf?url';
import lora_variableUrlitalic500 from './ttf/lora_variable/lora_variable-italic-500.ttf?url';
import lora_variableUrlitalic600 from './ttf/lora_variable/lora_variable-italic-600.ttf?url';
import lora_variableUrlitalic700 from './ttf/lora_variable/lora_variable-italic-700.ttf?url';
import lora_variableUrlnormal400 from './ttf/lora_variable/lora_variable-normal-400.ttf?url';
import lora_variableUrlnormal500 from './ttf/lora_variable/lora_variable-normal-500.ttf?url';
import lora_variableUrlnormal600 from './ttf/lora_variable/lora_variable-normal-600.ttf?url';
import lora_variableUrlnormal700 from './ttf/lora_variable/lora_variable-normal-700.ttf?url';

const fontConfig: FontConfig = {
  family: 'Lora',
  fonts: [
    {
      src: lora_variableUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: lora_variableUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: lora_variableUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: lora_variableUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: lora_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: lora_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: lora_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: lora_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
