import React from 'react';
import {
  Box,
  Text,
  RingProgress,
  Group,
  Stack,
  MantineColor,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface MatchGaugeProps {
  match?: number;
}

/**
 * A component that displays the match percentage between a job ad and candidate profile
 * using a circular gauge visualization
 */
export const MatchGauge: React.FC<MatchGaugeProps> = ({ match }) => {
  const { t } = useTranslation();

  if (match === undefined) return null;

  // Ensure match is within 0-100 range
  const validMatch = Math.min(100, Math.max(0, match));

  // Determine color based on match percentage
  const getColor = (value: number): MantineColor => {
    if (value >= 80) return 'green';
    if (value >= 60) return 'teal';
    if (value >= 40) return 'yellow';
    if (value >= 20) return 'orange';
    return 'red';
  };

  const color = getColor(validMatch);

  return (
    <Box my="xl">
      <Group justify="center" align="center">
        <RingProgress
          size={160}
          thickness={16}
          roundCaps
          sections={[{ value: validMatch, color }]}
          label={
            <Stack gap={0} align="center">
              <Text size="xl" fw={700} ta="center">
                {validMatch}%
              </Text>
              <Text size="sm" c="dimmed" ta="center">
                {t('resume.match')}
              </Text>
            </Stack>
          }
        />
      </Group>
    </Box>
  );
};

export default MatchGauge;
