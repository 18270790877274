import metadata from './dm_sans_variable.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import dm_sans_variableUrlitalic100 from './ttf/dm_sans_variable/dm_sans_variable-italic-100.ttf?url';
import dm_sans_variableUrlitalic200 from './ttf/dm_sans_variable/dm_sans_variable-italic-200.ttf?url';
import dm_sans_variableUrlitalic300 from './ttf/dm_sans_variable/dm_sans_variable-italic-300.ttf?url';
import dm_sans_variableUrlitalic400 from './ttf/dm_sans_variable/dm_sans_variable-italic-400.ttf?url';
import dm_sans_variableUrlitalic500 from './ttf/dm_sans_variable/dm_sans_variable-italic-500.ttf?url';
import dm_sans_variableUrlitalic600 from './ttf/dm_sans_variable/dm_sans_variable-italic-600.ttf?url';
import dm_sans_variableUrlitalic700 from './ttf/dm_sans_variable/dm_sans_variable-italic-700.ttf?url';
import dm_sans_variableUrlitalic800 from './ttf/dm_sans_variable/dm_sans_variable-italic-800.ttf?url';
import dm_sans_variableUrlitalic900 from './ttf/dm_sans_variable/dm_sans_variable-italic-900.ttf?url';
import dm_sans_variableUrlitalic1000 from './ttf/dm_sans_variable/dm_sans_variable-italic-1000.ttf?url';
import dm_sans_variableUrlnormal100 from './ttf/dm_sans_variable/dm_sans_variable-normal-100.ttf?url';
import dm_sans_variableUrlnormal200 from './ttf/dm_sans_variable/dm_sans_variable-normal-200.ttf?url';
import dm_sans_variableUrlnormal300 from './ttf/dm_sans_variable/dm_sans_variable-normal-300.ttf?url';
import dm_sans_variableUrlnormal400 from './ttf/dm_sans_variable/dm_sans_variable-normal-400.ttf?url';
import dm_sans_variableUrlnormal500 from './ttf/dm_sans_variable/dm_sans_variable-normal-500.ttf?url';
import dm_sans_variableUrlnormal600 from './ttf/dm_sans_variable/dm_sans_variable-normal-600.ttf?url';
import dm_sans_variableUrlnormal700 from './ttf/dm_sans_variable/dm_sans_variable-normal-700.ttf?url';
import dm_sans_variableUrlnormal800 from './ttf/dm_sans_variable/dm_sans_variable-normal-800.ttf?url';
import dm_sans_variableUrlnormal900 from './ttf/dm_sans_variable/dm_sans_variable-normal-900.ttf?url';
import dm_sans_variableUrlnormal1000 from './ttf/dm_sans_variable/dm_sans_variable-normal-1000.ttf?url';

const fontConfig: FontConfig = {
  family: 'DM Sans',
  fonts: [
    {
      src: dm_sans_variableUrlitalic100,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: dm_sans_variableUrlitalic200,
      fontStyle: 'italic',
      fontWeight: 200,
    },
    {
      src: dm_sans_variableUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: dm_sans_variableUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: dm_sans_variableUrlitalic500,
      fontStyle: 'italic',
      fontWeight: 500,
    },
    {
      src: dm_sans_variableUrlitalic600,
      fontStyle: 'italic',
      fontWeight: 600,
    },
    {
      src: dm_sans_variableUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: dm_sans_variableUrlitalic800,
      fontStyle: 'italic',
      fontWeight: 800,
    },
    {
      src: dm_sans_variableUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: dm_sans_variableUrlitalic1000,
      fontStyle: 'italic',
      fontWeight: 1000,
    },
    {
      src: dm_sans_variableUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: dm_sans_variableUrlnormal200,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    {
      src: dm_sans_variableUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: dm_sans_variableUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: dm_sans_variableUrlnormal500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: dm_sans_variableUrlnormal600,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    {
      src: dm_sans_variableUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: dm_sans_variableUrlnormal800,
      fontStyle: 'normal',
      fontWeight: 800,
    },
    {
      src: dm_sans_variableUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
    {
      src: dm_sans_variableUrlnormal1000,
      fontStyle: 'normal',
      fontWeight: 1000,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
