import metadata from './lato.metadata.json';
import type { FontConfig } from '../utils/types';
import type { FontMetadata } from '../utils/font-types';
import latoUrlitalic100 from './ttf/lato/lato-italic-100.ttf?url';
import latoUrlitalic300 from './ttf/lato/lato-italic-300.ttf?url';
import latoUrlitalic400 from './ttf/lato/lato-italic-400.ttf?url';
import latoUrlitalic700 from './ttf/lato/lato-italic-700.ttf?url';
import latoUrlitalic900 from './ttf/lato/lato-italic-900.ttf?url';
import latoUrlnormal100 from './ttf/lato/lato-normal-100.ttf?url';
import latoUrlnormal300 from './ttf/lato/lato-normal-300.ttf?url';
import latoUrlnormal400 from './ttf/lato/lato-normal-400.ttf?url';
import latoUrlnormal700 from './ttf/lato/lato-normal-700.ttf?url';
import latoUrlnormal900 from './ttf/lato/lato-normal-900.ttf?url';

const fontConfig: FontConfig = {
  family: 'Lato',
  fonts: [
    {
      src: latoUrlitalic100,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      src: latoUrlitalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: latoUrlitalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: latoUrlitalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: latoUrlitalic900,
      fontStyle: 'italic',
      fontWeight: 900,
    },
    {
      src: latoUrlnormal100,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    {
      src: latoUrlnormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: latoUrlnormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: latoUrlnormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: latoUrlnormal900,
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
  metadata: metadata as FontMetadata,
};

export default fontConfig;
